/*.requestDemoButton:hover {*/
/*  cursor: pointer;*/
/*  opacity: 0.9;*/
/*}*/

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.justify-content-lg-center {
  justify-content: center;
}

.switchButton div:hover {
  cursor: pointer;
  opacity: 0.9;
}



.media-read-more:hover {
  cursor: pointer;
  opacity: 0.9;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.ml-auto {
  margin-left: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media only screen and (max-width: 992px) {
  .d-lg-none {
    display: none;
  }
  .flex-lg-col-reverse {
    flex-direction: column-reverse;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
}

@media only screen and (max-width: 767px) {
  .d-md-none {
    display: none;
  }
}

html {
  overflow-x: hidden;
}

/*body {*/
/*  line-height: 26px;*/
/*  overflow-x: hidden;*/
/*}*/

ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

a {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: inherit;
  transition: inherit;
}

a:hover {
  color: inherit;
  text-decoration: inherit;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

button,
button:focus {
  border: 0;
  outline: 0;
}

a,
button {
  cursor: pointer;
}

img {
  max-width: 100%;
  /*height: auto;*/
}

.s-title-wrap {
  margin-bottom: 50px;
}

.s-title-wrap p {
  color: #6b727c;
  font-size: 18px;
}

.s-title {
  font-size: 40px;
  line-height: 50px;
}

.s-sub-title {
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000060;
  display: inline-block;
  margin-bottom: 10px;
}

.s-padding {
  background-color: #f4f4f4;
  padding-top: 110px;
  padding-bottom: 110px;
}

.s-padding-t {
  padding-top: 110px;
}

.s-padding-b {
  padding-bottom: 110px;
}

@media only screen and (max-width: 767px) {
  .s-padding {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .s-padding-b {
    padding-bottom: 90px;
  }
  .s-padding-t {
    padding-top: 90px;
  }
}
.x-hidden {
  overflow-x: hidden;
}

.y-hidden {
  overflow-y: hidden;
}

.z-index-99 {
  z-index: 99;
}


.i-list li {
  margin-bottom: 10px;
}
.i-list li .icon {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  margin-right: 6px;
  font-size: 13px;
}

.i-list li .text {
  font-weight: 500;
}

.i-list.ii-list li .icon {
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}

/*.slick-dots {*/
/*  display: flex !important;*/
/*  gap: 6px;*/
/*  margin-top: 50px;*/
/*}*/

/*.slick-dots li {*/
/*  width: 20px;*/
/*  height: 6px;*/
/*  border-radius: 6px;*/
/*  background: #b3d8fe;*/
/*  -webkit-transition: all 0.3s ease-in-out 0s;*/
/*  -moz-transition: all 0.3s ease-in-out 0s;*/
/*  -ms-transition: all 0.3s ease-in-out 0s;*/
/*  -o-transition: all 0.3s ease-in-out 0s;*/
/*  transition: all 0.3s ease-in-out 0s;*/
/*}*/

/*.slick-dots li.slick-active {*/
/*  width: 33px;*/
/*  background: #007cfb;*/
/*}*/

.video-thumb {
  position: relative;
}

.v-play-btn {
  height: 135px;
  width: 135px;
  display: inline-block;
  background: #fff;
  color: #000060;
  font-size: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 135px;
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: borderPulsing 2s linear 0s infinite;
}
.v-play-btn:hover {
  color: #000060;
}

@keyframes borderPulsing {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0px 0px 0px 45px rgba(255, 255, 255, 0);
  }
}
.video-popup {
  position: fixed;
  width: 100vw;
  background: rgba(0, 0, 0, 0.8196078431);
  height: 100vh;
  z-index: 999;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  right: 0;
  justify-content: center;
  align-items: center;
  display: none;
}
.video-popup.active {
  display: flex;
}

.video-popup-inner {
  position: relative;
}
.video-popup-inner .cls-btn {
  width: 50px;
  height: 50px;
  right: -40px;
  top: -40px;
  border: none;
  outline: none;
  border-radius: 50%;
  font-size: 20px;
  line-height: 51px;
  position: absolute;
}

.social-icons-s1 li,
.social-icons-s6 li,
.social-icons-s4 li {
  display: inline-block;
  margin-right: 15px;
}
.social-icons-s1 li a,
.social-icons-s6 li a,
.social-icons-s4 li a {
  width: 33px;
  height: 33px;
  display: inline-block;
  background: #fff;
  color: #007cfb;
  border-radius: 50%;
  line-height: 33px;
  text-align: center;
  box-shadow: 0px 4px 8px 2px rgba(0, 124, 251, 0.16);
  z-index: 9;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.social-icons-s1 li a::before,
.social-icons-s6 li a::before,
.social-icons-s4 li a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  box-shadow: inset 0 0 0 0 #007cfb;
  transition: 0.4s ease;
  left: 0px;
  top: 0px;
  z-index: -1;
}
.social-icons-s1 li a:hover,
.social-icons-s6 li a:hover,
.social-icons-s4 li a:hover {
  color: #fff;
}
.social-icons-s1 li a:hover::before,
.social-icons-s6 li a:hover::before,
.social-icons-s4 li a:hover::before {
  box-shadow: inset 0 0 35px 0 #007cfb;
}
.social-icons-s1 li:last-child,
.social-icons-s6 li:last-child,
.social-icons-s4 li:last-child {
  margin-right: 0;
}

.social-icons-s4 li a {
  box-shadow: 0px 4px 8px 2px rgba(218, 48, 149, 0.1);
  color: #da3095;
}
.social-icons-s4 li a::before {
  box-shadow: inset 0 0 0 0 #da3095;
}
.social-icons-s4 li a:hover::before {
  box-shadow: inset 0 0 50px #da3095;
}

.social-icons-s6 li a {
  box-shadow: 0px 4px 8px 2px rgba(218, 48, 149, 0.1);
  color: #3dcab1;
}
.social-icons-s6 li a::before {
  box-shadow: inset 0 0 0 0 #3dcab1;
}
.social-icons-s6 li a:hover::before {
  box-shadow: inset 0 0 50px #3dcab1;
}

.recent-posts > .rp-single {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 30px;
}
.recent-posts > .rp-single .thumb {
  flex-shrink: 0;
}
.recent-posts > .rp-single .thumb img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}
.recent-posts > .rp-single .content h4 {
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #c7c7c7;
  margin-bottom: 11px;
}
.recent-posts > .rp-single .content h4:hover {
  color: #4d93eb;
}
.recent-posts > .rp-single .content span {
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #c7c7c7;
}
.recent-posts > .rp-single .content span svg {
  color: #4d93eb;
  margin-right: 10px;
}

.info-list {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.info-list li {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.info-list li a {
  color: #c7c7c7;
}
.info-list li a svg {
  color: #4d93eb;
  margin-right: 20px;
}
.info-list li:hover a {
  color: #4d93eb;
}

.info-list-s2 {
  padding-left: 7px;
}
.info-list-s2 .s-info {
  position: relative;
  padding-left: 15px;
  margin-top: 5px;
}
.info-list-s2 .s-info p {
  color: #535353;
}
.info-list-s2 .s-info .title {
  font-size: 16px;
  line-height: 1em;
  font-weight: 600;
  color: #1c1c1c;
  position: relative;
  margin-bottom: 15px;
  padding: 0;
}
.info-list-s2 .s-info .title::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  left: -23px;
  top: 50%;
  position: absolute;
  transform: translateY(-53%);
  border-radius: 50%;
}
.info-list-s2 .s-info:last-child {
  border: none;
}
.info-list-s2 .s-info:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 15px;
  left: -2px;
  width: 1px;
  height: 100%;
  border: 1px dashed #000060;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-12px);
  }
}
.floating {
  animation: float 1s ease-in-out 0s infinite alternate none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) translateY(0px);
  }
  100% {
    transform: rotate(360deg) translateY(3px);
  }
}
.spin {
  animation: spin 2.5s ease-in-out 0s infinite alternate-reverse none;
}

@keyframes gradientMoving {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.gradient-moving {
  background-size: 400% 400% !important;
  animation: gradientMoving 3s ease infinite;
}

.stretch-container {
  max-width: 1520px;
  margin: auto;
}
@media only screen and (max-width: 1600px) {
  .stretch-container {
    max-width: 1194px;
  }
}
@media only screen and (max-width: 1400px) {
  .stretch-container {
    max-width: 1140px;
  }
}
@media only screen and (max-width: 1200px) {
  .stretch-container {
    max-width: 960px;
  }
}
@media only screen and (max-width: 992px) {
  .stretch-container {
    max-width: 720px;
  }
}
@media only screen and (max-width: 576px) {
  .stretch-container {
    max-width: 540px;
  }
}

.breadcrumb {
  text-align: center;
}
.breadcrumb li {
  position: relative;
  display: inline;
  padding: 0 15px;
}
.breadcrumb li::after {
  content: "-";
  position: absolute;
  top: 14px;
  font-size: 26px;
  line-height: 0;
  right: -10px;
  color: #fff;
}
.breadcrumb li a {
  color: #fff;
  font-size: 18px;
  font-family: "Mulish", serif;
}
.breadcrumb li:first-child {
  padding-left: 0px;
}
.breadcrumb li:last-child {
  padding-right: 0;
}
.breadcrumb li:last-child:after {
  display: none;
}

.pagination li {
  display: inline-block;
  margin-right: 8px;
}
.pagination li:last-child {
  margin: 0px;
}
.pagination li a {
  display: inline-block;
  color: #1c1c1c;
  background: linear-gradient(
    38.88deg,
    rgba(93, 64, 250, 0.3) 9.2%,
    rgba(78, 149, 237, 0.3) 86.44%
  );
  font-size: 20px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
}
.pagination li:hover a,
.pagination li.active a {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
}

.feature-s1,
.feature-s9,
.feature-s6,
.feature-s3,
.feature-s2,
.feature-s10,
.feature-s7 {
  border: 1px solid #f6f6f6;
  padding: 30px;
  border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.feature-s1 .icon,
.feature-s9 .icon,
.feature-s6 .icon,
.feature-s3 .icon,
.feature-s2 .icon,
.feature-s10 .icon,
.feature-s7 .icon {
  margin-bottom: 34px;
}
.feature-s1:hover,
.feature-s9:hover,
.feature-s6:hover,
.feature-s3:hover,
.feature-s2:hover,
.feature-s10:hover,
.feature-s7:hover,
.feature-s1.active,
.active.feature-s9,
.active.feature-s6,
.active.feature-s3,
.active.feature-s2,
.active.feature-s10,
.active.feature-s7 {
  transform: translateY(-8px);
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.07);
}
.feature-s1:hover .f-btn > svg,
.feature-s9:hover .f-btn > svg,
.feature-s6:hover .f-btn > svg,
.feature-s3:hover .f-btn > svg,
.feature-s2:hover .f-btn > svg,
.feature-s10:hover .f-btn > svg,
.feature-s7:hover .f-btn > svg,
.feature-s1.active .f-btn > svg,
.active.feature-s9 .f-btn > svg,
.active.feature-s6 .f-btn > svg,
.active.feature-s3 .f-btn > svg,
.active.feature-s2 .f-btn > svg,
.active.feature-s10 .f-btn > svg,
.active.feature-s7 .f-btn > svg {
  margin-left: 10px;
}
.feature-s1:hover .content h4 a,
.feature-s9:hover .content h4 a,
.feature-s6:hover .content h4 a,
.feature-s3:hover .content h4 a,
.feature-s2:hover .content h4 a,
.feature-s10:hover .content h4 a,
.feature-s7:hover .content h4 a,
.feature-s1.active .content h4 a,
.active.feature-s9 .content h4 a,
.active.feature-s6 .content h4 a,
.active.feature-s3 .content h4 a,
.active.feature-s2 .content h4 a,
.active.feature-s10 .content h4 a,
.active.feature-s7 .content h4 a {
  color: #000060;
}
.feature-s1:hover .content h4 a::before,
.feature-s9:hover .content h4 a::before,
.feature-s6:hover .content h4 a::before,
.feature-s3:hover .content h4 a::before,
.feature-s2:hover .content h4 a::before,
.feature-s10:hover .content h4 a::before,
.feature-s7:hover .content h4 a::before,
.feature-s1.active .content h4 a::before,
.active.feature-s9 .content h4 a::before,
.active.feature-s6 .content h4 a::before,
.active.feature-s3 .content h4 a::before,
.active.feature-s2 .content h4 a::before,
.active.feature-s10 .content h4 a::before,
.active.feature-s7 .content h4 a::before {
  width: 100%;
}

.feature-s1 > .content h4,
.feature-s9 > .content h4,
.feature-s6 > .content h4,
.feature-s3 > .content h4,
.feature-s2 > .content h4,
.feature-s10 > .content h4,
.feature-s7 > .content h4 {
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 17px;
}
.feature-s1 > .content h4 a,
.feature-s9 > .content h4 a,
.feature-s6 > .content h4 a,
.feature-s3 > .content h4 a,
.feature-s2 > .content h4 a,
.feature-s10 > .content h4 a,
.feature-s7 > .content h4 a {
  position: relative;
}
.feature-s1 > .content h4 a::before,
.feature-s9 > .content h4 a::before,
.feature-s6 > .content h4 a::before,
.feature-s3 > .content h4 a::before,
.feature-s2 > .content h4 a::before,
.feature-s10 > .content h4 a::before,
.feature-s7 > .content h4 a::before {
  width: 0;
  height: 5px;
  position: absolute;
  content: "";
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  bottom: 2px;
  transition: 0.4s ease-in-out;
  z-index: -1;
}
.feature-s1 > .content p,
.feature-s9 > .content p,
.feature-s6 > .content p,
.feature-s3 > .content p,
.feature-s2 > .content p,
.feature-s10 > .content p,
.feature-s7 > .content p {
  margin-bottom: 11px;
}
.feature-s1 > .content .f-btn,
.feature-s9 > .content .f-btn,
.feature-s6 > .content .f-btn,
.feature-s3 > .content .f-btn,
.feature-s2 > .content .f-btn,
.feature-s10 > .content .f-btn,
.feature-s7 > .content .f-btn {
  color: #000060;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.feature-s2,
.feature-s10,
.feature-s7 {
  /* background: url(../images/backgrounds/feature-s2-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fff;
  padding: 40px;
  padding-bottom: 35px;
}
.feature-s2 p,
.feature-s10 p,
.feature-s7 p {
  margin-bottom: 0px !important;
}
.feature-s2:hover,
.feature-s10:hover,
.feature-s7:hover,
.feature-s2.active,
.active.feature-s10,
.active.feature-s7 {
  background-color: #000060;
  transform: translateY(-8px);
}
.feature-s2:hover .icon img,
.feature-s10:hover .icon img,
.feature-s7:hover .icon img,
.feature-s2.active .icon img,
.active.feature-s10 .icon img,
.active.feature-s7 .icon img {
  transform: rotate(360deg);
  transition: 0.8s ease-in-out;
}
.feature-s2:hover .content h4,
.feature-s10:hover .content h4,
.feature-s7:hover .content h4,
.feature-s2.active .content h4,
.active.feature-s10 .content h4,
.active.feature-s7 .content h4 {
  color: #fff;
}
.feature-s2:hover .content p,
.feature-s10:hover .content p,
.feature-s7:hover .content p,
.feature-s2.active .content p,
.active.feature-s10 .content p,
.active.feature-s7 .content p {
  color: #fff;
}

.feature-s3 {
  background: linear-gradient(90deg, #090b34 0%, #03051c 100%);
  border-color: #020314;
}
.feature-s3:hover,
.feature-s3.active {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}
.feature-s3:hover .icon,
.feature-s3.active .icon {
  background: #fff;
}
.feature-s3:hover .icon img,
.feature-s3.active .icon img {
  filter: brightness(0.1);
}
.feature-s3:hover .content h4 a,
.feature-s3.active .content h4 a {
  color: #fff;
}
.feature-s3:hover .content p,
.feature-s3.active .content p {
  color: #fff;
}
.feature-s3:hover .content a.f-btn,
.feature-s3.active .content a.f-btn {
  color: #fff;
}

.feature-s3 .icon {
  width: 90px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.feature-s3 .content h4 {
  color: #fff;
}
.feature-s3 .content h4 a::before {
  background: #000060;
}
.feature-s3 .content p {
  color: #b8b8b8;
}
.feature-s3 .content a.f-btn {
  color: #b8b8b8;
}

.feature-area-inner > * {
  width: 35%;
}

.feature-s4 {
  /* background: url(../images/shapes/feature-s4-shape.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 340px;
  display: flex;
  align-items: center;
  position: relative;
}
.feature-s4 .badge {
  font-family: "Mulish", serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 55px;
  color: #ffffff;
  background: #007cfb;
  width: 68px;
  height: 68px;
  text-align: center;
  border-radius: 50%;
  border: 6px solid #fff;
  right: 92px;
  top: 20px;
  position: absolute;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.feature-s4:hover .badge {
  transform: scale(0.8);
}
.feature-s4:hover .content {
  box-shadow: none;
}
.feature-s4:hover .content h3 {
  color: #007cfb;
}

.feature-s4 .content {
  width: 280px;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
  padding: 30px;
  box-shadow: 0px 0px 20px rgba(0, 124, 251, 0.16);
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.feature-s4 .content h3 {
  font-size: 26px;
  line-height: 33px;
  color: #1c1c1c;
  margin-bottom: 25px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.feature-s4 .content p {
  line-height: 24px;
  color: #535353;
  margin-bottom: 0px;
}

.feature-s4.third {
  width: 30%;
  /* background: url(../images/shapes/feature-s4-shape2.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.feature-s4.third .content {
  margin-left: 30px;
}
.feature-s4.third .badge {
  right: 20px;
  top: 23px;
}

.feature-s5 {
  padding: 40px;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  margin: 0 12px;
}
.feature-s5:hover .content .icon {
  background: #007cfb;
  box-shadow: none;
}
.feature-s5:hover .content .icon img {
  filter: invert(85%) saturate(0%) brightness(136%) contrast(200%);
}
.feature-s5:hover .content h3 {
  color: #007cfb;
}

.feature-s5 .thumb img {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 5px;
}

.feature-s5 .content {
  text-align: center;
}
.feature-s5 .content .icon {
  width: 82px;
  height: 82px;
  background: #ddeeff;
  border: 5px solid #ffffff;
  box-shadow: 0px 6px 15px 2px rgba(0, 124, 251, 0.23);
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  margin: -42px auto 0;
  z-index: 9;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.feature-s5 .content .icon img {
  display: inline;
}
.feature-s5 .content h3 {
  font-size: 26px;
  line-height: 35px;
  color: #1c1c1c;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.feature-s5 .content p {
  line-height: 26px;
  color: #535353;
  margin: 0;
}

.feature-s6 .icon {
  width: 50px;
  height: 50px;
  margin-bottom: 22px;
}

.feature-s6 > .content h4 {
  font-size: 18px;
  line-height: 23px;
}
.feature-s6 > .content p {
  margin-bottom: 0px;
}

.feature-s7.active,
.feature-s7:hover {
  background: linear-gradient(82.77deg, #770993 0%, #0f1755 95.38%);
}
.feature-s7.active .icon svg circle {
  fill: #fff;
}
.feature-s7.active .icon svg path {
  fill: #da3095;
}
.feature-s7:hover .icon svg circle {
  fill: #fff;
}
.feature-s7:hover .icon svg path {
  fill: #da3095;
}

.feature-s9 {
  background-color: #fff;
}
.feature-s9 .icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #00003f;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.feature-s9:hover .icon {
  background-color: #3dcab1;
}

.feature-area-inner-6 .feature-s4 {
  /* background-image: url(../images/shapes/feature-s6-shape.png); */
}
.feature-area-inner-6 .feature-s4 .badge {
  background: #3dcab1;
}
.feature-area-inner-6 .feature-s4:hover .content h3 {
  color: #3dcab1;
}
.feature-area-inner-6 .feature-s4.third {
  /* background-image: url(../images/shapes/feature-s6-shape2.png); */
}

.home6-feature-s3-inner .feature-s5 .content .icon {
  background: #d2fff7;
  box-shadow: 0px 6px 15px 2px rgba(61, 202, 177, 0.2);
}
.home6-feature-s3-inner .feature-s5:hover .content .icon {
  background: #3dcab1;
}
.home6-feature-s3-inner .feature-s5:hover .content .icon svg path {
  fill: #fff;
}
.home6-feature-s3-inner .feature-s5:hover .content h3 {
  color: #3dcab1;
}

.feature-s10 {
  background: #fff;
  border-bottom: 4px solid transparent;
}
.feature-s10:hover {
  background: #fff;
  border-bottom-color: #fd4a18;
}
.feature-s10:hover .content h4 a {
  color: #fd4a18 !important;
}
.feature-s10:hover .content p {
  color: #535353;
}
.feature-s10 .icon {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
  margin-left: auto;
  margin-right: auto;
}
.feature-s10 .icon svg path {
  fill: #fff;
}
.feature-s10 .content {
  text-align: center;
}
.feature-s10 .content h4 a::before {
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}
.feature-s10:hover .icon svg path {
  fill: #fff;
}

.btn {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  border: none;
  line-height: 1;
  padding: 20px 24px;
  border-radius: 30px;
  display: inline-block;
}

.btn-s1,
.btn-s6,
.btn-s5,
.btn-s4,
.btn-s3,
.btn-s2 {
  overflow: hidden;
  position: relative;
  background-color: transparent;
  z-index: 9;
  color: #fff;
}
.btn-s1:hover,
.btn-s6:hover,
.btn-s5:hover,
.btn-s4:hover,
.btn-s3:hover,
.btn-s2:hover {
  color: #fff;
}
.btn-s1:hover::after,
.btn-s6:hover::after,
.btn-s5:hover::after,
.btn-s4:hover::after,
.btn-s3:hover::after,
.btn-s2:hover::after {
  width: calc(100% + 4px);
  border-color: #fff;
  left: -2px;
}
.btn-s1::before,
.btn-s6::before,
.btn-s5::before,
.btn-s4::before,
.btn-s3::before,
.btn-s2::before,
.btn-s1::after,
.btn-s6::after,
.btn-s5::after,
.btn-s4::after,
.btn-s3::after,
.btn-s2::after {
  content: "";
  position: absolute;
  display: block;
  z-index: -1;
}
.btn-s1::before,
.btn-s6::before,
.btn-s5::before,
.btn-s4::before,
.btn-s3::before,
.btn-s2::before {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgba(129, 14, 211) 100%
  );
  width: 100%;
  z-index: -2;
  left: 0px;
  transition: width 0.5s ease;
  transform: rotate(10deg);
  height: 150%;
  bottom: -25%;
}
.btn-s1::after,
.btn-s6::after,
.btn-s5::after,
.btn-s4::after,
.btn-s3::after,
.btn-s2::after {
  background: #5e40fb;
  right: 0px;
  transition: width 0.5s ease;
  height: 150%;
  top: -25%;
  right: -2px;
  width: 0px;
  transform: rotate(-10deg);
  z-index: -1;
  border-right: 1px solid rgba(255, 255, 255, 0.8117647059);
  border-left: 1px solid rgba(255, 255, 255, 0.8117647059);
}
.btn-s1.outlined,
.outlined.btn-s6,
.outlined.btn-s5,
.outlined.btn-s4,
.outlined.btn-s3,
.outlined.btn-s2 {
  border: 1px solid rgb(94, 64, 251);
  padding: 19px 23px;
  background: transparent;
}
.btn-s1.outlined::before,
.outlined.btn-s6::before,
.outlined.btn-s5::before,
.outlined.btn-s4::before,
.outlined.btn-s3::before,
.outlined.btn-s2::before {
  background: transparent;
}

.btn-s2 {
  position: relative;
  overflow: hidden;
}
.btn-s2:before {
  display: none;
}
.btn-s2:after {
  background-color: hsla(0deg, 0%, 100%, 0.2);
  bottom: -50%;
  content: "";
  position: absolute;
  top: -110%;
  transform: translate3d(-400%, 0, 0) rotate(35deg);
  width: 50px;
  z-index: 1;
}
.btn-s2:hover {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}
.btn-s2:hover:after {
  transform: translate3d(130%, 0, 0) rotate(35deg);
  transition: transform 1s ease-in-out;
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.btn-s3::before {
  background: #007cfb;
}

.btn-s4::before {
  background: linear-gradient(82.77deg, #770993 0%, #0f1755 95.38%);
}

.btn-s5 {
  min-width: 175px;
  text-align: center;
}
.btn-s5::before {
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}

.hero-s5-btn2 {
  border: 1px solid #fd4a18;
  color: #fff;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.hero-s5-btn2:hover {
  background-color: #fd4a18;
  color: #fff;
}

.header-search-btn {
  width: 60px;
  height: 60px;
  background-color: #3dcab1;
  border-radius: 50%;
  margin-top: 15px;
}

.btn-s6 {
  background: #3dcab1;
}
.btn-s6::before {
  background: #3dcab1;
}

@keyframes pulsingBorderS2 {
  from {
    box-shadow: 0 0 0 0px rgba(77, 148, 235, 0.662745098);
  }
  to {
    box-shadow: 0 0 0 25px rgba(77, 148, 235, 0.1058823529);
  }
}
.play-btn-sm {
  font-size: 24px;
  border: none;
  background: #007cfb;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  color: #fff;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  animation: pulsingBorderS2 1.5s linear 0s infinite;
}
.play-btn-sm:hover {
  color: #fff;
}

.pricing-t-s1,
.pricing-t-s2 {
  padding-top: 50px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  padding-bottom: 50px;
  padding-left: 40px;
  padding-right: 40px;
  z-index: 9;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.pricing-t-s1 *,
.pricing-t-s2 * {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

/*.pricing-t-s1:hover .pt-header .pt-ribbon,*/
/*.pricing-t-s2:hover .pt-header .pt-ribbon,*/
/*.pricing-t-s1.active .pt-header .pt-ribbon,*/
/*.active.pricing-t-s2 .pt-header .pt-ribbon {*/
/*  background-color: #fff;*/
/*  color: #000060;*/
/*}*/
/*.pricing-t-s1:hover .pt-header .price > *,*/
/*.pricing-t-s2:hover .pt-header .price > *,*/
/*.pricing-t-s1.active .pt-header .price > *,*/
/*.active.pricing-t-s2 .pt-header .price > * {*/
/*  color: #fff;*/
/*}*/
/*.pricing-t-s1:hover .pt-body > .pt-features li,*/
/*.pricing-t-s2:hover .pt-body > .pt-features li,*/
/*.pricing-t-s1.active .pt-body > .pt-features li,*/
/*.active.pricing-t-s2 .pt-body > .pt-features li {*/
/*  color: #fff;*/
/*}*/

.pricing-t-s1::before,
.pricing-t-s2::before,
.pricing-t-s1::after,
.pricing-t-s2::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  left: 0px;
  top: 0px;
  z-index: -2;
}
.pricing-t-s1::after,
.pricing-t-s2::after {
  height: 0px;
  background: #000060;
  top: auto;
  bottom: 0px;
  transition: height 0.5s linear;
}
.pricing-t-s1:hover::after,
.pricing-t-s2:hover::after {
  top: 0px;
  bottom: auto;
  height: 100%;
  z-index: -1;
}
.pricing-t-s1.active::after,
.active.pricing-t-s2::after {
  background: #000060;
}
.pricing-t-s1.active::before,
.active.pricing-t-s2::before {
  background: #000060;
}

.pricing-t-s1 > .pt-header .icon,
.pricing-t-s2 > .pt-header .icon {
  margin-bottom: 25px;
}
.pricing-t-s1 > .pt-header .price,
.pricing-t-s2 > .pt-header .price {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.pricing-t-s1 > .pt-header .price > *,
.pricing-t-s2 > .pt-header .price > * {
  color: #333;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.pricing-t-s1 > .pt-header .price h3,
.pricing-t-s2 > .pt-header .price h3 {
  font-family: "Mulish", serif;
  font-style: normal;
  font-weight: 800;
  font-size: 55px;
  line-height: 69px;
}
.pricing-t-s1 > .pt-header .price .p-pre,
.pricing-t-s2 > .pt-header .price .p-pre {
  font-size: 20px;
  font-weight: 700;
  font-family: "Mulish", serif;
  align-self: flex-start;
  display: inline-block;
  margin-right: 5px;
}
.pricing-t-s1 > .pt-header .price .p-suf,
.pricing-t-s2 > .pt-header .price .p-suf {
  font-size: 18px;
  font-weight: 500;
}

.pricing-t-s1 > .pt-body .pt-features,
.pricing-t-s2 > .pt-body .pt-features {
  border-top: 1px solid #ececec;
  margin-top: 15px;
  padding-top: 35px;
}

.pricing-t-s1 > .pt-body .pt-features li,
.pricing-t-s2 > .pt-body .pt-features li {
  margin-bottom: 16px;
  color: #333;
}
.pricing-t-s1 > .pt-body .pt-features li .icon,
.pricing-t-s2 > .pt-body .pt-features li .icon {
  margin-right: 6px;
}

.pricing-t-s1 > .pt-body .pt-features li .text,
.pricing-t-s2 > .pt-body .pt-features li .text {
  font-size: 18px;
}

.pricing-t-s1 > .pt-footer,
.pricing-t-s2 > .pt-footer {
  padding-top: 20px;
}
.pricing-t-s1 > .pt-footer .pt-btn.btn,
.pricing-t-s2 > .pt-footer .pt-btn.btn {
  width: 170px;
}

.pricing-t-s2 {
  background-color: #fff;
  padding-top: 0px;
  padding-bottom: 40px;
  border: 2px solid rgba(129, 14, 211);
  border-radius: 20px;
}

.pricing-t-s2::before,
.pricing-t-s2::after {
  display: none;
}

/*.pricing-t-s2:hover,*/
/*.pricing-t-s2.active {*/
/*  background: rgba(129, 14, 211)*/
/*}*/
/*.pricing-t-s2:hover .pt-header .pt-ribbon,*/
/*.pricing-t-s2.active .pt-header .pt-ribbon {*/
/*  background: #fff;*/
/*}*/

/*.pricing-t-s2:hover .pt-header .price .p-suf,*/
/*.pricing-t-s2.active .pt-header .price .p-suf {*/
/*  color: #fff;*/
/*}*/

/*.pricing-t-s2:hover .pt-body .pt-features li .text,*/
/*.pricing-t-s2.active .pt-body .pt-features li .text {*/
/*  color: #fff;*/
/*}*/
/*.pricing-t-s2:hover .btn-s1,*/
/*.pricing-t-s2:hover .btn-s2,*/
/*.pricing-t-s2:hover .btn-s3,*/
/*.pricing-t-s2:hover .btn-s4,*/
/*.pricing-t-s2:hover .btn-s5,*/
/*.pricing-t-s2:hover .btn-s6,*/
/*.pricing-t-s2.active .btn-s1,*/
/*.pricing-t-s2.active .btn-s2,*/
/*.pricing-t-s2.active .btn-s3,*/
/*.pricing-t-s2.active .btn-s4,*/
/*.pricing-t-s2.active .btn-s5,*/
/*.pricing-t-s2.active .btn-s6 {*/
/*  color: #1c1c1c;*/
/*}*/
/*.pricing-t-s2:hover .btn-s1::after,*/
/*.pricing-t-s2:hover .btn-s2::after,*/
/*.pricing-t-s2:hover .btn-s3::after,*/
/*.pricing-t-s2:hover .btn-s4::after,*/
/*.pricing-t-s2:hover .btn-s5::after,*/
/*.pricing-t-s2:hover .btn-s6::after,*/
/*.pricing-t-s2.active .btn-s1::after,*/
/*.pricing-t-s2.active .btn-s2::after,*/
/*.pricing-t-s2.active .btn-s3::after,*/
/*.pricing-t-s2.active .btn-s4::after,*/
/*.pricing-t-s2.active .btn-s5::after,*/
/*.pricing-t-s2.active .btn-s6::after {*/
/*  width: 100%;*/
/*}*/

.pricing-t-s2 > .pt-header {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10px;
}

.pricing-t-s2 > .pt-header .pt-ribbon {
  position: static;
  font-family: "Mulish", serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #1c1c1c;
  padding: 30px 0px 20px 0;
  background: #ffffff
}

.pt-descriptText {
  font-family: "Mulish", serif;
  font-weight: 400;
  font-size: 15px;
  color: #3f3f3f;
  margin-bottom: 25px;
  text-align: left;
}

.pricing-t-s2 > .pt-header .price h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  margin-block-start: 5px;
  margin-block-end: 5px;
}

.pricing-t-s2 > .pt-header .price .p-suf {
  font-family: "Mulish", serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #1c1c1c;
}

.pricing-t-s2 > .pt-body .pt-features li {
  margin: 0;
  text-align: left;
}
.pricing-t-s2 > .pt-body .pt-features li .text {
  font-weight: 500;
  font-size: 15px;
  line-height: 34px;
  color: #101010;
}

.pricing-t-s2 > .pt-footer {
  padding-top: 50px;
}
.pricing-t-s2 > .pt-footer .btn-s1::after,
.pricing-t-s2 > .pt-footer .btn-s2::after,
.pricing-t-s2 > .pt-footer .btn-s3::after,
.pricing-t-s2 > .pt-footer .btn-s4::after,
.pricing-t-s2 > .pt-footer .btn-s5::after,
.pricing-t-s2 > .pt-footer .btn-s6::after {
  background: #fff;
}

.pricing-t-s6 {
  border: 1px solid #3dcab1;
}

.pricing-t-s6 > .pt-body .pt-features {
  border-top: 1px solid #ececec;
}

.pricing-t-s6:hover {
  border-color: transparent;
}
.pricing-t-s6:hover > .pt-body .pt-features {
  border-color: #2d2d8f;
}

.pricing-t-s6.active {
  border-color: transparent;
}

.pricing-t-s6.active > .pt-body .pt-features {
  border-color: #2d2d8f;
}

.single-tes-s1 .desc,
.single-tes-s2 .desc {
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 21px;
}
.single-tes-s1 .desc img,
.single-tes-s2 .desc img {
  display: inline;
}

.single-tes-s1 > .auth-info,
.single-tes-s2 > .auth-info {
  display: flex;
  gap: 30px;
  align-items: center;
}
.single-tes-s1 > .auth-info .details .name,
.single-tes-s2 > .auth-info .details .name {
  font-family: "Mulish", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #007cfb;
  margin-bottom: 0px;
}
.single-tes-s1 > .auth-info .details .designation,
.single-tes-s2 > .auth-info .details .designation {
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #333;
}
.single-tes-s1 > .auth-info .details .rating,
.single-tes-s2 > .auth-info .details .rating {
  margin-top: 10px;
}
.single-tes-s1 > .auth-info .details .rating > *,
.single-tes-s2 > .auth-info .details .rating > * {
  color: #ffc107;
  font-size: 18px;
}
.single-tes-s1 > .auth-info .thumb,
.single-tes-s2 > .auth-info .thumb {
  flex-shrink: 0;
  border: 5px solid #fff;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 25px rgba(0, 124, 251, 0.15);
}

.single-tes-s2 .icon-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.single-tes-s2 .icon-rating .rating {
  color: #ffc107;
}
.single-tes-s2 .desc {
  font-size: 16px;
  line-height: 26px;
  font-style: normal;
  color: #bbb;
  margin-bottom: 30px;
}
.single-tes-s2 .auth-info .thumb {
  border: none;
  box-shadow: none;
}
.single-tes-s2 .auth-info .details .name {
  font-size: 26px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 3px;
}
.single-tes-s2 .auth-info .details .designation {
  color: #b8b8b8;
}

.home5-single-tes-s5 .desc {
  color: #fff;
}

.home5-single-tes-s5 > .auth-info .details .name {
  color: #fd4a18;
}
.home5-single-tes-s5 > .auth-info .details .designation {
  color: #fff;
}

.funfact-s1,
.funfact-s3 {
  border: 1px solid #323286;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding: 23px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.funfact-s1 .content h4,
.funfact-s3 .content h4 {
  font-weight: 800;
  font-size: 30px;
  line-height: 35px;
  color: #fff;
  margin-bottom: 6px;
}
.funfact-s1 .content p,
.funfact-s3 .content p {
  color: #ffffff;
  margin-bottom: 0px;
}
.funfact-s1::before,
.funfact-s3::before {
  background-color: hsla(0deg, 0%, 100%, 0.2);
  bottom: -50%;
  content: "";
  position: absolute;
  top: -110%;
  transform: translate3d(-500%, 0, 0) rotate(35deg);
  transition: transform 0.4s ease-in-out;
  visibility: hidden;
  width: 50px;
  z-index: 1;
}
.funfact-s1:hover,
.funfact-s3:hover {
  transform: translateY(-12px);
}
.funfact-s1:hover::before,
.funfact-s3:hover::before {
  visibility: visible;
  transform: translate3d(500%, 0, 0) rotate(35deg);
}

.funfact-s2 {
  display: flex;
  align-items: center;
  gap: 25px;
}
.funfact-s2 .icon {
  width: 80px;
  height: 80px;
  background: #fff;
  box-shadow: 0px 5px 12px 2px rgba(0, 124, 251, 0.16);
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
}
.funfact-s2 .icon img {
  width: 33px;
}

.funfact-s2 .content h4 {
  font-weight: 800;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
  margin-bottom: 5px;
}
.funfact-s2 .content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  color: #333333;
  margin: 0;
}

.funfact-s3:hover {
  background: #000060;
}
.funfact-s3:hover .content h4 {
  color: #fff;
}
.funfact-s3:hover .content p {
  color: #fff;
}

.funfact-s3 .content h4 {
  color: #1c1c1c;
}
.funfact-s3 .content p {
  color: #535353;
}

.funfact-s4 .icon {
  box-shadow: 0px 5px 12px 2px rgba(61, 202, 177, 0.16);
}

.b-post-s1,
.b-post-s6,
.b-post-s4,
.b-post-s2 {
  background: #ffffff;
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  overflow: hidden;
}

.b-post-s1 > .thumb,
.b-post-s6 > .thumb,
.b-post-s4 > .thumb,
.b-post-s2 > .thumb {
  position: relative;
  overflow: hidden;
}
.b-post-s1 > .thumb::before,
.b-post-s6 > .thumb::before,
.b-post-s4 > .thumb::before,
.b-post-s2 > .thumb::before {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  font-size: 42px;
  position: absolute;
  content: "+";
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: 0.4s;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.b-post-s1 > .thumb img,
.b-post-s6 > .thumb img,
.b-post-s4 > .thumb img,
.b-post-s2 > .thumb img {
  width: 100%;
  transition: 0.3s ease-in-out;
}
.b-post-s1 > .thumb:hover::before,
.b-post-s6 > .thumb:hover::before,
.b-post-s4 > .thumb:hover::before,
.b-post-s2 > .thumb:hover::before {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.b-post-s1 > .thumb:hover img,
.b-post-s6 > .thumb:hover img,
.b-post-s4 > .thumb:hover img,
.b-post-s2 > .thumb:hover img {
  transform: scale(1.1);
}

.b-post-s1 > .content,
.b-post-s6 > .content,
.b-post-s4 > .content,
.b-post-s2 > .content {
  padding: 32px 34px 26px;
}
.b-post-s1 > .content .title,
.b-post-s6 > .content .title,
.b-post-s4 > .content .title,
.b-post-s2 > .content .title {
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
  margin-top: 7px;
  -webkit-transition: all 0.1s ease-in-out 0s;
  -moz-transition: all 0.1s ease-in-out 0s;
  -ms-transition: all 0.1s ease-in-out 0s;
  -o-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
.b-post-s1 > .content .title:hover,
.b-post-s6 > .content .title:hover,
.b-post-s4 > .content .title:hover,
.b-post-s2 > .content .title:hover {
  color: #4d93eb;
}
.b-post-s1 > .content .meta li,
.b-post-s6 > .content .meta li,
.b-post-s4 > .content .meta li,
.b-post-s2 > .content .meta li {
  display: inline-block;
  margin-right: 30px;
}
.b-post-s1 > .content .meta li .icon,
.b-post-s6 > .content .meta li .icon,
.b-post-s4 > .content .meta li .icon,
.b-post-s2 > .content .meta li .icon {
  color: #4d93eb;
}
.b-post-s1 > .content .meta li:last-child,
.b-post-s6 > .content .meta li:last-child,
.b-post-s4 > .content .meta li:last-child,
.b-post-s2 > .content .meta li:last-child {
  margin-right: 0px;
}
.b-post-s1 > .content .btm,
.b-post-s6 > .content .btm,
.b-post-s4 > .content .btm,
.b-post-s2 > .content .btm {
  padding-top: 14px;
  margin-top: 22px;
  border-top: 1px solid #dff5f1;
}
.b-post-s1 > .content .btm .readmore,
.b-post-s6 > .content .btm .readmore,
.b-post-s4 > .content .btm .readmore,
.b-post-s2 > .content .btm .readmore {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: #007cfb;
}

.b-post-s2 .content {
  background: #090b34;
}
.b-post-s2 .content .title {
  color: #fff;
}
.b-post-s2 .content .title:hover {
  color: #fff;
}
.b-post-s2 .content .meta li .text {
  color: #bbb;
}
.b-post-s2 .content .meta li .icon {
  color: #5132ef;
}
.b-post-s2 .content .btm {
  border-color: #141756;
}
.b-post-s2 .content .btm .readmore {
  color: #bbb;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.b-post-s2 .content .btm .readmore:hover {
  color: #5132ef;
}

.b-post-s2 > .thumb {
  background: #020314;
}
.b-post-s2 > .thumb .date {
  position: absolute;
  bottom: 45px;
  right: 45px;
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  padding: 15px;
  border-radius: 5px;
  max-width: 90px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.b-post-s2 > .thumb .date p {
  font-family: "Mulish", serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  margin: 0;
}
.b-post-s2 > .thumb:hover .date {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.b-post-s4 .content .meta li .icon {
  color: #da3095;
}
.b-post-s4 .content .title:hover {
  color: #da3095;
}
.b-post-s4 .content .btm .readmore {
  color: #1c1c1c;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.b-post-s4 .content .btm .readmore:hover {
  color: #da3095;
}

.b-post-s5 .content .meta li .icon {
  color: #fd4a18;
}
.b-post-s5 .content .title:hover {
  color: #fd4a18;
}
.b-post-s5 .content .btm {
  border-top: 1px solid #c6e2ff;
}
.b-post-s5 .content .btm .readmore {
  color: #1c1c1c;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.b-post-s5 .content .btm .readmore:hover {
  color: #fd4a18;
}

.b-post-s6 .content .meta li .icon {
  color: #3dcab1;
}
.b-post-s6 .content .title:hover {
  color: #3dcab1;
}
.b-post-s6 .content .btm .readmore {
  color: #1c1c1c;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.b-post-s6 .content .btm .readmore:hover {
  color: #3dcab1;
}

.sponsers-slider-s1,
.sponsers-slider-s2 {
  background: #020314;
}
.sponsers-slider-s1 .s-title-wrap,
.sponsers-slider-s2 .s-title-wrap {
  text-align: center;
}

.sponsers-slider-s1 .sponsers-container,
.sponsers-slider-s2 .sponsers-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 30px;
}
.sponsers-slider-s1 .sponsers-container .s-sponser,
.sponsers-slider-s2 .sponsers-container .s-sponser {
  width: 100%;
  text-align: center;
  padding: 22px 0px;
  background: #090b34;
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
}
.sponsers-slider-s1 .sponsers-container .s-sponser:hover,
.sponsers-slider-s2 .sponsers-container .s-sponser:hover {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  transform: translateY(-10px);
}
.sponsers-slider-s1 .sponsers-container .s-sponser:hover img,
.sponsers-slider-s2 .sponsers-container .s-sponser:hover img {
  filter: brightness(200%) contrast(200%) saturate(100%) blur(0px)
    hue-rotate(0deg);
}

.sponsers-slider-s2 {
  background: none;
}

.s-project-s1 {
  position: relative;
  padding: 0px 10px;
}
.s-project-s1:hover .content {
  transform: translateY(0);
}
.s-project-s1:hover .thumb img {
  transform: scale(1.05);
}

.s-project-s1 .content {
  transform: translateY(100%);
  transition: 0.3s ease-in-out;
  position: absolute;
  bottom: 0px;
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  display: flex;
  padding: 23px 60px;
  border-radius: 5px;
  gap: 55px;
}

.s-project-s1 .thumb {
  overflow: hidden;
}
.s-project-s1 .thumb img {
  border-radius: 6px;
  transition: 0.2s;
}

.s-project-s1 .content .p-left .name {
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
}
.s-project-s1 .content .p-left .p-catagories li {
  display: inline-block;
}
.s-project-s1 .content .p-left .p-catagories li::after {
  content: ",";
  color: #fff;
}
.s-project-s1 .content .p-left .p-catagories li:last-child::after {
  display: none;
}
.s-project-s1 .content .p-left .p-catagories li a {
  color: #fff;
}

.s-project-s1 .content .p-right {
  display: flex;
  align-items: center;
}
.s-project-s1 .content .p-right .r-btn {
  font-size: 20px;
  width: 42px;
  height: 42px;
  display: inline-block;
  text-align: center;
  line-height: 42px;
  color: #000;
  background: #fff;
}

.s-project-s2 {
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  padding: 0 12px;
}
.s-project-s2:hover {
  transform: translateY(-10px);
}
.s-project-s2:hover .content {
  transform: translateX(0);
}

.s-project-s2-inner {
  position: relative;
  overflow: hidden;
}

.s-project-s2 .thumb img {
  width: 370px;
  height: 510px;
  object-fit: cover;
  border-radius: 5px;
}

.s-project-s2 .content {
  position: absolute;
  top: 0px;
  left: 0%;
  bottom: 70px;
  right: 70px;
  background: rgba(0, 125, 251, 0.8392156863);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: 40px;
  padding-left: 40px;
  border-radius: 0 0px 6px 0;
  transform: translateX(-102%);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.s-project-s2 .content h3 {
  font-size: 25px;
  line-height: 35px;
  color: #fff;
  margin-bottom: 30px;
}
.s-project-s2 .content p {
  color: #fff;
  margin: 0;
}
.s-project-s2 .content .btn {
  width: 50px;
  height: 50px;
  text-align: center;
  padding: 0px;
  line-height: 50px;
  background: #fff;
  color: #007cfb;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.s-project-s2 .content:hover .btn {
  transform: translateX(10px);
}

.s-project-s3 .content {
  background-image: linear-gradient(
    89.96deg,
    rgba(49, 47, 249, 0.7) 0%,
    rgba(133, 7, 255, 0.7)
  );
}
.s-project-s3 .content .btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab-contents .tab-pane {
  display: none;
  animation: tabFade 1s;
}
.tab-contents .tab-pane.active {
  display: block;
}

@keyframes tabFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tab-s1 .tab-links .tab-btn {
  font-family: "Mulish", serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
  background: #090b34;
  border-radius: 50px;
  border: 0;
  padding: 12px 30px;
  margin-right: 12px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.tab-s1 .tab-links .tab-btn:last-child {
  margin-right: 0px;
}
.tab-s1 .tab-links .tab-btn:hover,
.tab-s1 .tab-links .tab-btn.active {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}

.tab-s1 .tab-contents {
  margin-top: 30px;
}
.tab-s1 .tab-contents .tab-pane p {
  color: #b8b8b8;
  margin-bottom: 35px;
}
.tab-s1 .tab-contents .tab-pane .list-b {
  display: flex;
  justify-content: flex-start;
  gap: 50px;
}
.tab-s1 .tab-contents .tab-pane .list-b .i-list li .text {
  color: #fff;
}

.pricing-tab-s1 .pricing-toogle {
  padding: 5px;
  border: 1px solid #e9e9e9;
  width: fit-content;
  margin: auto;
  border-radius: 35px;
}
.pricing-tab-s1 .pricing-toogle button {
  border: none;
  background: #fff;
  width: 150px;
  height: 60px;
  display: inline-block;
  border-radius: 30px;
  color: #1c1c1c;
}
.pricing-tab-s1 .pricing-toogle button.active,
.pricing-tab-s1 .pricing-toogle button:hover {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
}

.team-slider-s1 ul.slick-dots {
  justify-content: center;
}
.team-slider-s1 ul.slick-dots li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #232767;
}
.team-slider-s1 ul.slick-dots li.slick-active {
  width: 14px;
  height: 14px;
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}

.single-team-s1,
.single-team-s2 {
  padding: 0 12px;
}
.single-team-s1:hover .content::after,
.single-team-s2:hover .content::after {
  width: 100%;
}
.single-team-s1:hover .thumb .social-icons-s1 li,
.single-team-s2:hover .thumb .social-icons-s1 li,
.single-team-s1:hover .thumb .social-icons-s4 li,
.single-team-s2:hover .thumb .social-icons-s4 li,
.single-team-s1:hover .thumb .social-icons-s6 li,
.single-team-s2:hover .thumb .social-icons-s6 li {
  transform: translateX(-25px);
}

.single-team-s1 .content,
.single-team-s2 .content {
  border-radius: 5px;
  text-align: center;
  padding: 12px 0;
  margin-top: 20px;
  position: relative;
  z-index: 9;
}
.single-team-s1 .content .name,
.single-team-s2 .content .name {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}
.single-team-s1 .content .designation,
.single-team-s2 .content .designation {
  color: #b8b8b8;
  margin: 0;
}
.single-team-s1 .content::before,
.single-team-s2 .content::before,
.single-team-s1 .content::after,
.single-team-s2 .content::after {
  content: "";
  position: absolute;
  height: 100%;
  top: 0px;
}
.single-team-s1 .content::before,
.single-team-s2 .content::before {
  background: #090b34;
  width: 100%;
  left: 0px;
  border-radius: 5px;
  z-index: -2;
}
.single-team-s1 .content::after,
.single-team-s2 .content::after {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  left: 0px;
  top: 0px;
  width: 0px;
  z-index: -1;
  border-radius: 5px;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.single-team-s1 .thumb,
.single-team-s2 .thumb {
  background: #090b34;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.single-team-s1 .thumb img,
.single-team-s2 .thumb img {
  border-radius: 5px;
}
.single-team-s1 .thumb .social-icons-s1,
.single-team-s2 .thumb .social-icons-s1,
.single-team-s1 .thumb .social-icons-s4,
.single-team-s2 .thumb .social-icons-s4,
.single-team-s1 .thumb .social-icons-s6,
.single-team-s2 .thumb .social-icons-s6 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 0;
  bottom: 30px;
}
.single-team-s1 .thumb .social-icons-s1 li,
.single-team-s2 .thumb .social-icons-s1 li,
.single-team-s1 .thumb .social-icons-s4 li,
.single-team-s2 .thumb .social-icons-s4 li,
.single-team-s1 .thumb .social-icons-s6 li,
.single-team-s2 .thumb .social-icons-s6 li {
  margin-right: 0px;
  transform: translateX(100%);
  transition: 0.3s ease;
}
.single-team-s1 .thumb .social-icons-s1 li:nth-child(2),
.single-team-s2 .thumb .social-icons-s1 li:nth-child(2),
.single-team-s1 .thumb .social-icons-s4 li:nth-child(2),
.single-team-s2 .thumb .social-icons-s4 li:nth-child(2),
.single-team-s1 .thumb .social-icons-s6 li:nth-child(2),
.single-team-s2 .thumb .social-icons-s6 li:nth-child(2) {
  transition-delay: 0.1s;
}
.single-team-s1 .thumb .social-icons-s1 li:nth-child(3),
.single-team-s2 .thumb .social-icons-s1 li:nth-child(3),
.single-team-s1 .thumb .social-icons-s4 li:nth-child(3),
.single-team-s2 .thumb .social-icons-s4 li:nth-child(3),
.single-team-s1 .thumb .social-icons-s6 li:nth-child(3),
.single-team-s2 .thumb .social-icons-s6 li:nth-child(3) {
  transition-delay: 0.2s;
}
.single-team-s1 .thumb .social-icons-s1 li:nth-child(4),
.single-team-s2 .thumb .social-icons-s1 li:nth-child(4),
.single-team-s1 .thumb .social-icons-s4 li:nth-child(4),
.single-team-s2 .thumb .social-icons-s4 li:nth-child(4),
.single-team-s1 .thumb .social-icons-s6 li:nth-child(4),
.single-team-s2 .thumb .social-icons-s6 li:nth-child(4) {
  transition-delay: 0.3s;
}
.single-team-s1 .thumb .social-icons-s1 li a,
.single-team-s2 .thumb .social-icons-s1 li a,
.single-team-s1 .thumb .social-icons-s4 li a,
.single-team-s2 .thumb .social-icons-s4 li a,
.single-team-s1 .thumb .social-icons-s6 li a,
.single-team-s2 .thumb .social-icons-s6 li a {
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 22px;
  box-shadow: 0px 4px 8px rgba(0, 124, 251, 0.16);
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.single-team-s1 .thumb .social-icons-s1 li:hover a,
.single-team-s2 .thumb .social-icons-s1 li:hover a,
.single-team-s1 .thumb .social-icons-s4 li:hover a,
.single-team-s2 .thumb .social-icons-s4 li:hover a,
.single-team-s1 .thumb .social-icons-s6 li:hover a,
.single-team-s2 .thumb .social-icons-s6 li:hover a {
  box-shadow: none;
  transform: scale(0.95);
}

.single-team-s2:hover .thumb::before {
  width: 100%;
}

.single-team-s2 .content::before {
  background: #000060;
}
.single-team-s2 .content::after {
  background: linear-gradient(263.86deg, #4b91ea 1.42%, #0a2d98 97.62%);
}

.single-team-s2 .thumb {
  position: relative;
}
.single-team-s2 .thumb img {
  width: 100%;
}
.single-team-s2 .thumb .social-icons-s1,
.single-team-s2 .thumb .social-icons-s4,
.single-team-s2 .thumb .social-icons-s6 {
  bottom: 30px;
}
.single-team-s2 .thumb .social-icons-s1 li a,
.single-team-s2 .thumb .social-icons-s4 li a,
.single-team-s2 .thumb .social-icons-s6 li a {
  box-shadow: none;
}
.single-team-s2 .thumb::before {
  content: "";
  width: 0;
  height: 100%;
  background: rgba(0, 0, 96, 0.4588235294);
  position: absolute;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.single-team-s3 .content::before {
  background: #00003f;
}
.single-team-s3 .content::after {
  background: #3dcab1;
}

.single-team-s3:hover .content p {
  color: #fff;
}
.single-team-s3:hover .thumb::before {
  background: #00003f;
  opacity: 0.5;
}

.single-team-s2 .thumb {
  position: relative;
}
.single-team-s2 .thumb img {
  width: 100%;
}
.single-team-s2 .thumb .social-icons-s1,
.single-team-s2 .thumb .social-icons-s4,
.single-team-s2 .thumb .social-icons-s6 {
  bottom: 30px;
}
.single-team-s2 .thumb .social-icons-s1 li a,
.single-team-s2 .thumb .social-icons-s4 li a,
.single-team-s2 .thumb .social-icons-s6 li a {
  box-shadow: none;
}
.single-team-s2 .thumb::before {
  content: "";
  width: 0;
  height: 100%;
  background: rgba(0, 0, 96, 0.4588235294);
  position: absolute;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.single-team-s2 .thumb .social-icons-s1 li a,
.single-team-s2 .thumb .social-icons-s4 li a,
.single-team-s2 .thumb .social-icons-s6 li a {
  background: #3dcab1;
}

.home6-team-s .team-slider-s1 ul.slick-dots li {
  background: #00003f;
  opacity: 0.3;
}
.home6-team-s .team-slider-s1 ul.slick-dots li.slick-active {
  background: #00003f;
  opacity: 1;
}

.progress-s1 .s-progress .p-title {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.progress-s1 .s-progress .progress-bar {
  width: 100%;
  height: 10px;
  background: #cce5fe;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 3px;
}
.progress-s1 .s-progress .progress-bar .progress-bar__inner {
  background: #007cfb;
  height: 4px;
  border-radius: 4px;
  position: relative;
  width: 0;
  transition: 2s ease-out;
}
.progress-s1 .s-progress .progress-bar .progress-bar__inner .shape {
  position: absolute;
  width: 0px;
  height: 0px;
  border-top: solid 10px #4d93eb;
  border-right: solid 8px transparent;
  border-left: solid 8px transparent;
  bottom: 10px;
  right: -6px;
}

.progress-s2 .s-progress .progress-bar {
  background: linear-gradient(
    89.96deg,
    rgba(49, 47, 249, 0.2) 0%,
    rgba(133, 7, 255, 0.2)
  );
}
.progress-s2 .s-progress .progress-bar .progress-bar__inner {
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}
.progress-s2 .s-progress .progress-bar .progress-bar__inner .shape {
  border-top-color: #5132ef;
}

.d-page-banner {
  /* background: url(../images/backgrounds/banner-bg.png); */
  height: 300px;
  display: flex;
  align-items: center;
  margin-top: -44px;
  padding-top: 44px;
}
.d-page-banner .content h1 {
  font-size: 45px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.accordion-s1 .s-accordion {
  background: #ffffff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 20px;
}
.accordion-s1 .s-accordion.active {
  padding-bottom: 20px;
}
.accordion-s1 .s-accordion.active .s-accordion__h {
  border-color: #e7e7e7;
  margin-bottom: 20px;
}
.accordion-s1 .s-accordion.active .s-accordion__h .icon::after {
  transform: rotate(0deg) translateX(-50%);
}

.accordion-s1 .s-accordion > .s-accordion__h {
  padding: 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.accordion-s1 .s-accordion > .s-accordion__h h3 {
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #1c1c1c;
}
.accordion-s1 .s-accordion > .s-accordion__h .icon {
  position: relative;
}
.accordion-s1 .s-accordion > .s-accordion__h .icon::before,
.accordion-s1 .s-accordion > .s-accordion__h .icon::after {
  width: 20px;
  height: 4px;
  background: #1c1c1c;
  content: "";
  position: absolute;
  border-radius: 2px;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
}
.accordion-s1 .s-accordion > .s-accordion__h .icon::after {
  transform: translateX(-50%) rotate(90deg);
}

.s-accordion__b {
  display: block;
  height: 0;
  overflow: hidden;
  padding: 0 25px;
  transition: height 0.65s ease;
}
.s-accordion__b p {
  line-height: 24px;
}

.portfolio-gallery {
  /* background: url(../images/backgrounds/portfolio-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.portfolio-gallery .s-title-wrap {
  text-align: center;
}

.portfolio-item {
  width: 50%;
  padding: 12px;
}

.portfolio-item__inner {
  padding: 15px;
  background-color: #fff;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.portfolio-item__inner:hover .thumb::before {
  height: 100%;
  opacity: 1;
}
.portfolio-item__inner:hover .content {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.portfolio-item .thumb {
  position: relative;
}
.portfolio-item .thumb::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0%;
  background: linear-gradient(1deg, #5c43f9 10%, transparent);
  border-radius: 5px;
  left: 0px;
  bottom: 0px;
  opacity: 0.7;
  transition: 0.4s ease-in;
}
.portfolio-item .thumb img {
  width: 100%;
  object-fit: cover;
}

.portfolio-item .content {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 55px;
  left: 0px;
  right: 0px;
  transform: translateY(40px);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease;
  transition-delay: 0.2s;
}
.portfolio-item .content h3 {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 5px;
}
.portfolio-item .content span {
  color: #ffffff;
}
.portfolio-item .content a {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 0px;
  background: #fff;
  border-radius: 50%;
  color: #1c1c1c;
  font-size: 18px;
  text-align: center;
  line-height: 50px;
}

.porftolio-filter-menu {
  text-align: center;
  padding-bottom: 25px;
}

.porftolio-filter-menu .filter-btn {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #1c1c1c;
  border: none;
  background: linear-gradient(
    79.45deg,
    rgba(93, 64, 250, 0.3) 0%,
    rgba(78, 149, 237, 0.3) 99.11%
  );
  border-radius: 5px;
  padding: 12px 20px;
  outline: none;
  margin: 8px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.porftolio-filter-menu .filter-btn:hover,
.porftolio-filter-menu .filter-btn.current {
  background: linear-gradient(79.45deg, #5d40fa 0%, #4e95ed 99.11%);
  color: #fff;
}

.contact-form-s1 {
  background: linear-gradient(225.03deg, #5c43f9 -1.09%, #4e93ed 101.5%);
  border-radius: 5px;
  padding: 70px;
}

.contact-form-s1 input {
  background-color: #fff;
  display: block;
  border: 0;
  outline: 0;
  border-radius: 30px;
  width: 100%;
  margin-bottom: 15px;
  height: 60px;
  font-size: 16px;
  padding: 18px 30px;
}

.contact-form-s1 input[type="number"]::-webkit-outer-spin-button,
.contact-form-s1 input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact-form-s1 input[type="number"] {
  -moz-appearance: textfield;
}

.contact-form-s1 input::placeholder,
.contact-form-s1 textarea::placeholder {
  color: #a9a9a9;
}

.contact-form-s1 textarea {
  border: 0;
  outline: 0;
  background: #fff;
  border-radius: 30px;
  height: 135px;
  width: 100%;
  padding: 20px 30px;
  display: block;
  font-size: 16px;
}

.contact-form-s1 button[type="submit"] {
  background: #442bff;
  border: 0;
  outline: 0;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  height: 60px;
  width: 100%;
  display: block;
  margin-top: 20px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

.contact-form-s1 button[type="submit"]:hover {
  opacity: 0.7;
}

.header-s1,
.header-s2 {
  position: relative;
  z-index: 99;
}

.header-s1__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0px;
  padding-bottom: 25px;
}

.header-s1__top > .p-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-s1__top > .p-right {
  display: flex;
  gap: 40px;
}

.tb-single-info {
  display: flex;
  gap: 17px;
}
.tb-single-info .icon {
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  box-shadow: inset 0 0 0 35px #000060;
  transition: box-shadow 0.4s, transform 0.3s;
}
.tb-single-info p {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin: 0;
  text-decoration-line: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 2px;
  text-underline-offset: 1px;
  -webkit-transition: all 0.15s ease-in-out 0s;
  -moz-transition: all 0.15s ease-in-out 0s;
  -ms-transition: all 0.15s ease-in-out 0s;
  -o-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}
.tb-single-info span {
  font-family: "Mulish", sans-serif;
  color: #000;
}
.tb-single-info:hover .icon {
  box-shadow: inset 0 0 0 2px #000060;
  color: #000060;
}
.tb-single-info:hover p {
  text-decoration-color: #000;
}

.header-s1__bottom {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 15px 40px;
  -webkit-box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.08);
}
.header-s1__bottom .p-left a {
  margin-top: 8px;
}

.header-s1__bottom > .p-right {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-s1__bottom > .logo {
  display: none;
}

.m-nav {
  display: flex;
  justify-content: space-between;
}

.m-nav > ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 33px;
}
.m-nav > ul > li {
  padding: 7px 0;
  font-size: 18px;
  color: #000;
  -webkit-transition: all 0.1s ease-in-out 0s;
  -moz-transition: all 0.1s ease-in-out 0s;
  -ms-transition: all 0.1s ease-in-out 0s;
  -o-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
.m-nav > ul > li.dropdown {
  position: relative;
}
.m-nav > ul > li.dropdown svg {
  font-size: 12px;
  margin-left: 2px;
}
.m-nav > ul > li.dropdown:hover .d-menu {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}
.m-nav > ul > li a {
  position: relative;
}
.m-nav > ul > li a::before {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  background: linear-gradient(45deg, #000060 0%, rgb(94, 64, 251) 100%);
  bottom: -2px;
  display: block;
  right: 0;
  transition: 0.4s;
}
.m-nav > ul > li a:hover::before {
  left: 0;
  width: 100%;
}
.m-nav > ul > li:hover > a {
  color: #000060;
}

.m-nav li.dropdown .d-menu {
  position: absolute;
  min-width: 240px;
  background: #fff;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 20px 30px;
  left: 0px;
  top: 100%;
  transform: translateY(10px);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 99;
}
.m-nav li.dropdown .d-menu li {
  margin-bottom: 7px;
  display: block;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.m-nav li.dropdown .d-menu li::before {
  content: "";
  display: none;
  left: 0px;
  top: 50%;
  width: 0;
  height: 2px;
  position: absolute;
  background: linear-gradient(45deg, #000060 0%, rgb(94, 64, 251) 100%);
  transition: 0.3s ease-in-out;
}
.m-nav li.dropdown .d-menu li:hover {
  color: #000060;
}
.m-nav li.dropdown .d-menu li:hover::before {
  width: 12px;
}
.m-nav li.dropdown .d-menu li a {
  font-size: 16px;
}
.m-nav li.dropdown .d-menu li:last-child {
  margin-bottom: 0px;
}

.header-s2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.header-s2 .header-s1__top .p-left {
  justify-content: flex-start;
}
.header-s2 .header-s1__top .p-right .social-icons-s1 li a,
.header-s2 .header-s1__top .p-right .social-icons-s4 li a,
.header-s2 .header-s1__top .p-right .social-icons-s6 li a {
  background: linear-gradient(
    45deg,
    rgb(82, 47, 240) 0%,
    rgb(78, 148, 237) 100%
  );
  color: #fff;
  box-shadow: 0px 4px 8px 2px rgba(0, 124, 251, 0.16);
}

.header-s2 .header-s1__top .tb-single-info {
  padding: 0 40px;
  position: relative;
}
.header-s2 .header-s1__top .tb-single-info .content span {
  color: #fff;
}
.header-s2 .header-s1__top .tb-single-info .content p {
  color: #fff;
}
.header-s2 .header-s1__top .tb-single-info:after {
  content: "";
  height: 70%;
  width: 1px;
  position: absolute;
  right: 0px;
  top: 15%;
  background: linear-gradient(90deg, #000060 0%, rgb(77, 147, 235) 100%);
}
.header-s2 .header-s1__top .tb-single-info:first-child {
  padding-left: 0px;
}
.header-s2 .header-s1__top .tb-single-info:last-child {
  padding-right: 0px;
}
.header-s2 .header-s1__top .tb-single-info:last-child:after {
  display: none;
}
.header-s2 .header-s1__top .tb-single-info:hover .icon {
  color: #fff;
}

.header-s2 .header-s1__bottom {
  border-radius: 5px;
  position: relative;
  padding: 20px 50px;
}
.header-s2 .header-s1__bottom::after {
  width: calc(100% - 20px);
  height: 10px;
  background: #171973;
  position: absolute;
  content: "";
  bottom: -10px;
  border-radius: 0px 0px 5px 5px;
  left: 10px;
  z-index: -1;
}
.header-s2 .header-s1__bottom .p-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header-s2 .header-s1__bottom .p-middle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-s3 {
  position: relative;
  z-index: 999;
}

.tb-single-info-s2,
.header-s6 .header-top-container .tb-single-info-s6,
.header-s4 .header-top-container .tb-single-info-s4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}
.tb-single-info-s2 .icon,
.header-s6 .header-top-container .tb-single-info-s6 .icon,
.header-s4 .header-top-container .tb-single-info-s4 .icon {
  color: #4d93eb;
}
.tb-single-info-s2 p,
.header-s6 .header-top-container .tb-single-info-s6 p,
.header-s4 .header-top-container .tb-single-info-s4 p {
  line-height: 20px;
  color: #000000;
  margin: 0;
  text-decoration-line: underline;
  text-decoration-color: transparent;
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;
  -webkit-transition: all 0.1s ease-in-out 0s;
  -moz-transition: all 0.1s ease-in-out 0s;
  -ms-transition: all 0.1s ease-in-out 0s;
  -o-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
.tb-single-info-s2 p:hover,
.header-s6 .header-top-container .tb-single-info-s6 p:hover,
.header-s4 .header-top-container .tb-single-info-s4 p:hover {
  color: #007cfb;
  text-decoration-color: #007cfb;
}

.header-top-container {
  border-bottom: 1px solid #cce5fe;
}

.header-s3__top {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
}
.header-s3__top .p-left {
  display: flex;
  gap: 55px;
}

.header-s3__bottom,
.header-s6__bottom,
.header-s4__bottom {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}
.header-s3__bottom .p-left,
.header-s6__bottom .p-left,
.header-s4__bottom .p-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header-s3__bottom .p-center,
.header-s6__bottom .p-center,
.header-s4__bottom .p-center {
  display: flex;
  align-items: center;
}
.header-s3__bottom .p-center .m-nav > ul > li a::before,
.header-s6__bottom .p-center .m-nav > ul > li a::before,
.header-s4__bottom .p-center .m-nav > ul > li a::before {
  background: #007cfb;
}
.header-s3__bottom .p-center .m-nav > ul > li a:hover,
.header-s6__bottom .p-center .m-nav > ul > li a:hover,
.header-s4__bottom .p-center .m-nav > ul > li a:hover {
  color: #007cfb;
}
.header-s3__bottom .p-center .m-nav li.dropdown .d-menu li::before,
.header-s6__bottom .p-center .m-nav li.dropdown .d-menu li::before,
.header-s4__bottom .p-center .m-nav li.dropdown .d-menu li::before {
  background: #4d93eb;
}

.header-s4 .header-top-container {
  border-bottom: 1px solid #e9c5da;
}
.header-s4 .header-top-container .tb-single-info-s4 .icon {
  color: #da3095;
}
.header-s4 .header-top-container .tb-single-info-s4 .content p:hover {
  color: #da3095;
  text-decoration-color: #da3095;
}

.header-s4__bottom .p-center .m-nav > ul > li a:hover {
  color: #da3095;
}
.header-s4__bottom .p-center .m-nav > ul > li a::before {
  background-color: #da3095;
}
.header-s4__bottom .p-right {
  margin-top: 8px;
}

.home5-header {
  position: relative;
  z-index: 11;
}
.home5-header .home5-container {
  max-width: 1626px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.home5-header .header-s1__bottom {
  padding: 18px 15px 18px 40px;
  border-radius: 500px;
}
.home5-header .header-s1__bottom .p-left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.home5-header .header-s1__bottom .p-left .home5-header-form {
  padding: 5px;
  background: linear-gradient(
    89.96deg,
    rgba(49, 47, 249, 0.1) 0%,
    rgba(133, 7, 255, 0.1) 100%
  );
  border-radius: 500px;
  width: 100%;
  max-width: 383px;
  height: 60px;
  margin-right: 20px;
  position: relative;
}
.home5-header .header-s1__bottom .p-left .home5-header-form .search-field {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 15px;
  /* background: url("../images/icons/search.svg") no-repeat left/55px 19px; */
}
.home5-header
  .header-s1__bottom
  .p-left
  .home5-header-form
  .search-field:focus {
  background: none;
}
.home5-header .header-s1__bottom .p-left .home5-header-form .search-btn {
  font-weight: 600;
  background-color: #fd4a18;
  color: #fff;
  min-width: 121px;
  height: 50px;
  border-radius: 500px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.home5-header .header-s1__bottom .p-left .home5-header-form .search-btn:hover {
  background-color: #fff;
  color: #fd4a18;
}
@media only screen and (max-width: 1400px) {
  .home5-header .header-s1__bottom .p-left .home5-header-form {
    display: none;
  }
}
.home5-header .header-s1__bottom .p-right .m-nav > ul > li a:hover {
  color: #fd4a18;
}
.home5-header .header-s1__bottom .p-right .m-nav > ul > li a::before {
  background: #fd4a18;
}
.home5-header .header-s1__bottom .p-right .m-nav > ul > li > a:hover {
  color: #fd4a18;
}
.home5-header .header-s1__bottom .p-right .m-nav > ul > li > a:hover::after {
  background-color: #3dcab1;
}

.tb-single-info-7 .icon {
  box-shadow: inset 0 0 0 transparent;
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
  transition: all 0.4s ease-in-out;
}
.tb-single-info-7:hover .icon {
  box-shadow: inset 0 0 0 0 transparent;
  color: #fff;
  background: linear-gradient(89.96deg, #8507ff 0%, #312ff9 100%);
}

.header-s6 .header-top-container {
  border-bottom: 1px solid #e1f9f4;
}
.header-s6 .header-top-container .tb-single-info-s6 .icon {
  color: #3dcab1;
}
.header-s6 .header-top-container .tb-single-info-s6 .content p:hover {
  color: #3dcab1;
  text-decoration-color: #3dcab1;
}

.header-s6__bottom {
  padding: 0;
}
.header-s6__bottom .p-center .m-nav > ul {
  gap: 20px;
}
.header-s6__bottom .p-center .m-nav > ul > li a:hover {
  color: #3dcab1;
}
.header-s6__bottom .p-center .m-nav > ul > li a::before {
  background: #3dcab1;
}
.header-s6__bottom .p-center .m-nav > ul > li > a {
  position: relative;
  padding: 25px 15px;
  height: 100%;
  display: block;
  z-index: 1;
}
.header-s6__bottom .p-center .m-nav > ul > li > a::before {
  display: none;
}
.header-s6__bottom .p-center .m-nav > ul > li > a::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 120%;
  z-index: -1;
  transition: all 0.4s ease-in-out;
}
.header-s6__bottom .p-center .m-nav > ul > li > a:hover {
  color: #fff;
}
.header-s6__bottom .p-center .m-nav > ul > li > a:hover::after {
  background-color: #3dcab1;
}

.search-form-main {
  background: #fff;
  -webkit-box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.15);
  border-top: 3px solid #3dcab1;
  padding: 15px;
  position: absolute;
  right: 0;
  top: 180%;
  width: 400px;
  z-index: 9999;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.search-form-main.active-search {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  top: 95%;
}
.search-form-main::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #3dcab1;
  content: "";
  position: absolute;
  right: 15px;
  top: -10px;
}
.search-form-main .search-field {
  border-radius: 3px 0 0 3px;
  padding: 4px 10px;
  width: 100%;
  height: 40px;
  display: block;
  border: 2px solid #3dcab1;
  outline: 0;
}
.search-form-main .search-field,
.search-form-main .search-field::placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #535353;
}
.search-form-main .search-submit {
  border: medium none;
  border-radius: 0 3px 3px 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 7px 0 10px;
  width: 100px;
  height: 40px;
  background: #3dcab1;
  cursor: pointer;
  display: block;
}

.header-s3.sticky {
  padding-bottom: 90px;
}
.header-s3.sticky .header-bottom-container {
  position: fixed;
  left: 0;
  top: -30px;
  transform: translateY(28px);
  transition: 0.6s ease;
  right: 0;
  background: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}

.mobile-header {
  display: none;
}

.mobile-header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
}

#nav-opn-btn {
  width: 40px;
  height: 40px;
  border: none;
  background: #007cfb;
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
}

#offcanvas-nav {
  background: transparent;
  position: absolute;
  left: -300px;
  top: 0px;
  z-index: 99;
  width: 300px;
  height: 100vh;
  z-index: 999999;
  transition: 0.5s ease;
}
#offcanvas-nav::before {
  content: "";
  width: 0%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: 0.5s ease 0.3s;
}

#offcanvas-nav.open {
  left: 0px;
}
#offcanvas-nav.open::before {
  width: 100%;
}

#offcanvas-nav .m-nav {
  position: relative;
  background: #fff;
  padding-top: 80px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 50px;
  z-index: 9;
  display: block;
  height: 100%;
  overflow-y: scroll;
}

#nav-cls-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  background: none;
  border: none;
  outline: none;
  font-size: 22px;
}

#offcanvas-nav .m-nav .nav-links {
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
}
#offcanvas-nav .m-nav .nav-links li {
  border-bottom: 1px solid #d3d3d3;
}
#offcanvas-nav .m-nav .nav-links li a {
  font-size: 16px;
}
#offcanvas-nav .m-nav .nav-links li a::before {
  display: none;
}

#offcanvas-nav li:last-of-type {
  border: 0 !important;
}

#offcanvas-nav .m-nav .nav-links .dropdown .d-menu {
  position: relative;
  box-shadow: none;
  padding-left: 20px;
  padding-top: 15px !important;
  padding-top: 0px;
  visibility: visible;
  opacity: 1;
  display: none;
}
#offcanvas-nav .m-nav .nav-links .dropdown .d-menu li {
  padding: 5px 0px;
}
#offcanvas-nav .m-nav .nav-links .dropdown .d-menu li a {
  font-size: 15px;
}
#offcanvas-nav .m-nav .nav-links .dropdown:hover .d-menu {
  display: block;
}

#offcanvas-nav .m-nav .logo {
  margin-bottom: 40px;
}

#offcanvas-nav .m-nav > .social-icons-s1,
#offcanvas-nav .m-nav > .social-icons-s4,
#offcanvas-nav .m-nav > .social-icons-s6 {
  gap: 12px;
  margin-top: 50px;
}
#offcanvas-nav .m-nav > .social-icons-s1 li a::before,
#offcanvas-nav .m-nav > .social-icons-s4 li a::before,
#offcanvas-nav .m-nav > .social-icons-s6 li a::before {
  display: none;
}

footer .f-title {
  color: #fff;
  font-size: 26px;
  line-height: 26px;
  margin-bottom: 33px;
}
footer .f-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
footer .f-menu li {
  position: relative;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
footer .f-menu li a {
  color: #c7c7c7;
}
footer .f-menu li::before {
  content: "";
  height: 2px;
  width: 0px;
  display: block;
  background: #fff;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
footer .f-menu li:hover {
  padding-left: 15px;
}
footer .f-menu li:hover::before {
  width: 12px;
}
footer .f-menu li:hover a {
  color: #4d93eb;
}
footer .footer-cr {
  border-top: 1px solid #fff;
  padding: 20px 0;
}
footer .footer-cr .footer-cr-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .footer-cr .footer-cr-container * {
  color: #c7c7c7;
}
footer .footer-cr .footer-cr-container .p-left p {
  margin: 0;
}
footer .footer-cr .footer-cr-container ul.cr-menu {
  display: flex;
}
footer .footer-cr .footer-cr-container ul.cr-menu li {
  padding: 0 30px;
  border-right: 1px solid #c7c7c7;
  -webkit-transition: all 0.1s ease-in-out 0s;
  -moz-transition: all 0.1s ease-in-out 0s;
  -ms-transition: all 0.1s ease-in-out 0s;
  -o-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
footer .footer-cr .footer-cr-container ul.cr-menu li:hover a {
  color: #4d93eb;
}
footer .footer-cr .footer-cr-container ul.cr-menu li:last-child {
  border: 0px;
  margin-right: 0;
}

.footer-s1,
.footer-s2 {
  padding-top: 120px;
  /* background: url(../images/backgrounds/footer-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #01070e;
}

.footer-s1 .footer-cta,
.footer-s2 .footer-cta {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  padding: 30px 50px;
  align-items: center;
  justify-content: space-between;
}
.footer-s1 .footer-cta .p-left span,
.footer-s2 .footer-cta .p-left span {
  font-family: "Mulish", serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #333;
}
.footer-s1 .footer-cta .p-left h2,
.footer-s2 .footer-cta .p-left h2 {
  font-weight: 700;
  font-size: 26px;
  line-height: 36px;
  color: #333;
}

.footer-s1 .footer-cta > .p-right .f-subs-form,
.footer-s2 .footer-cta > .p-right .f-subs-form {
  background: #ecf5ff;
  width: 535px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 9px 0 20px;
  border-radius: 5px;
}
.footer-s1 .footer-cta > .p-right .f-subs-form input,
.footer-s2 .footer-cta > .p-right .f-subs-form input {
  border: 0px;
  color: #b1b1b1;
  outline: 0px;
  width: 300px;
  background: transparent;
}
.footer-s1 .footer-cta > .p-right .f-subs-form button,
.footer-s2 .footer-cta > .p-right .f-subs-form button {
  border: none;
  outline: none;
  height: 52px;
  width: 180px;
  border-radius: 5px;
  background: linear-gradient(85.59deg, #5d40fa 0%, #4e95ed 97.25%);
  color: #fff;
  font-size: 18px;
}

.footer-s1 .row.f-main,
.footer-s2 .row.f-main {
  margin-top: 90px;
  margin-bottom: 85px;
}

.footer-s1 .widget-1 .desc,
.footer-s2 .widget-1 .desc {
  color: #c7c7c7;
  margin-top: 30px;
  margin-bottom: 28px;
}

.footer-s2 .social-icons-s1 li a,
.footer-s2 .social-icons-s4 li a,
.footer-s2 .social-icons-s6 li a {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
  background: linear-gradient(40.23deg, #522df0 6.43%, #4e95ed 87.7%);
}
.footer-s2 .f-menu li:hover a {
  color: #8e74ff;
}
.footer-s2 .info-list li:hover a {
  color: #8e74ff;
}
.footer-s2 .info-list li a svg {
  color: #8e74ff;
}

.footer-s2 .recent-posts > .rp-single .content span svg {
  color: #8e74ff;
}
.footer-s2 .recent-posts > .rp-single .content h4:hover {
  color: #8e74ff;
}

.footer-s2 .footer-cta {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}
.footer-s2 .footer-cta .p-left span {
  color: #fff;
}
.footer-s2 .footer-cta .p-left h2 {
  color: #fff;
}
.footer-s2 .footer-cta .p-right .f-subs-form {
  background: linear-gradient(45deg, #6675ff 0%, #69a4ff 100%);
}
.footer-s2 .footer-cta .p-right .f-subs-form input {
  color: #fff;
}
.footer-s2 .footer-cta .p-right .f-subs-form input::placeholder {
  color: #fff;
}
.footer-s2 .footer-cta .p-right .f-subs-form button {
  color: #020314;
  background: #fff;
}

.footer-s3 .footer-cta > .p-right .f-subs-form {
  background: linear-gradient(
    82.77deg,
    rgba(119, 9, 147, 0.1),
    rgba(15, 23, 85, 0.1)
  );
}
.footer-s3 .footer-cta > .p-right .f-subs-form button {
  background: linear-gradient(82.77deg, #770993 0%, #0f1755 95.38%);
}
.footer-s3 .social-icons-s1 li a svg,
.footer-s3 .social-icons-s4 li a svg,
.footer-s3 .social-icons-s6 li a svg {
  color: #da3095;
}
.footer-s3 .social-icons-s1 li a::before,
.footer-s3 .social-icons-s4 li a::before,
.footer-s3 .social-icons-s6 li a::before {
  box-shadow: inset 0 0 0 0 #da3095;
}
.footer-s3 .social-icons-s1 li a:hover svg,
.footer-s3 .social-icons-s4 li a:hover svg,
.footer-s3 .social-icons-s6 li a:hover svg {
  color: #fff;
}
.footer-s3 .social-icons-s1 li a:hover::before,
.footer-s3 .social-icons-s4 li a:hover::before,
.footer-s3 .social-icons-s6 li a:hover::before {
  box-shadow: inset 0 0 50px #da3095;
}
.footer-s3 .f-menu li a:hover {
  color: #da3095;
}
.footer-s3 .recent-posts .rp-single .content h4 a:hover {
  color: #da3095;
}
.footer-s3 .recent-posts .rp-single .content span svg {
  color: #da3095;
}
.footer-s3 .widget-4 .info-list li a:hover {
  color: #da3095;
}
.footer-s3 .widget-4 .info-list li a svg {
  color: #da3095;
}
.footer-s3 .footer-cr .cr-menu li a:hover {
  color: #da3095 !important;
}

.footer-s5 {
  background-color: #00003f;
}

.footer-s5 .footer-cta > .p-right .f-subs-form {
  background: #ecf5ff;
}
.footer-s5 .footer-cta > .p-right .f-subs-form button {
  background: #3dcab1;
}
.footer-s5 .social-icons-s1 li a svg,
.footer-s5 .social-icons-s4 li a svg,
.footer-s5 .social-icons-s6 li a svg {
  color: #3dcab1;
}
.footer-s5 .social-icons-s1 li a::before,
.footer-s5 .social-icons-s4 li a::before,
.footer-s5 .social-icons-s6 li a::before {
  box-shadow: inset 0 0 0 0 #3dcab1;
}
.footer-s5 .social-icons-s1 li a:hover svg,
.footer-s5 .social-icons-s4 li a:hover svg,
.footer-s5 .social-icons-s6 li a:hover svg {
  color: #fff;
}
.footer-s5 .social-icons-s1 li a:hover::before,
.footer-s5 .social-icons-s4 li a:hover::before,
.footer-s5 .social-icons-s6 li a:hover::before {
  box-shadow: inset 0 0 50px #3dcab1;
}
.footer-s5 .f-menu li a:hover {
  color: #3dcab1;
}
.footer-s5 .recent-posts .rp-single .content h4 a:hover {
  color: #3dcab1;
}
.footer-s5 .recent-posts .rp-single .content span svg {
  color: #3dcab1;
}
.footer-s5 .widget-4 .info-list li a:hover {
  color: #3dcab1;
}
.footer-s5 .widget-4 .info-list li a svg {
  color: #3dcab1;
}
.footer-s5 .footer-cr .cr-menu li a:hover {
  color: #3dcab1 !important;
}

.footer-s6 .footer-cta > .p-right .f-subs-form {
  background: #ecf5ff;
}
.footer-s6 .footer-cta > .p-right .f-subs-form button {
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}
.footer-s6 .social-icons-s1 li a,
.footer-s6 .social-icons-s4 li a,
.footer-s6 .social-icons-s6 li a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-s6 .social-icons-s1 li a svg,
.footer-s6 .social-icons-s4 li a svg,
.footer-s6 .social-icons-s6 li a svg {
  color: #fd4a18;
}
.footer-s6 .social-icons-s1 li a::before,
.footer-s6 .social-icons-s4 li a::before,
.footer-s6 .social-icons-s6 li a::before {
  box-shadow: inset 0 0 0 0 transparent;
}
.footer-s6 .social-icons-s1 li a:hover,
.footer-s6 .social-icons-s4 li a:hover,
.footer-s6 .social-icons-s6 li a:hover {
  transform: translateY(-4px);
}
.footer-s6 .social-icons-s1 li a:hover svg,
.footer-s6 .social-icons-s4 li a:hover svg,
.footer-s6 .social-icons-s6 li a:hover svg {
  color: #fff;
}
.footer-s6 .social-icons-s1 li a:hover::before,
.footer-s6 .social-icons-s4 li a:hover::before,
.footer-s6 .social-icons-s6 li a:hover::before {
  box-shadow: inset 0 0 50px transparent;
}
.footer-s6 .f-menu li a:hover {
  color: #fd4a18;
}
.footer-s6 .recent-posts .rp-single .content h4 a:hover {
  color: #fd4a18;
}
.footer-s6 .recent-posts .rp-single .content span svg {
  color: #fd4a18;
}
.footer-s6 .widget-4 .info-list li a:hover {
  color: #fd4a18;
}
.footer-s6 .widget-4 .info-list li a svg {
  color: #fd4a18;
}
.footer-s6 .footer-cr .cr-menu li a:hover {
  color: #fd4a18 !important;
}

.hero-s1 {
  background-color: #000060;
  background-size: cover;
  padding-top: 195px;
  padding-bottom: 150px;
  margin-top: -44px;
  position: relative;
}
.hero-s1 .shapes > * {
  position: absolute;
}
.hero-s1 .shapes .shp-4 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;
}
.hero-s1 .shapes .shp-2 {
  position: absolute;
  right: 0px;
  top: 0px;
}
.hero-s1 .shapes .shp-3 {
  left: 100px;
  bottom: 40%;
}

.hero-s1__content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 11;
}
.hero-s1__content .content-sh {
  font-weight: 400;
  font-size: 25px;
  line-height: 31px;
  color: #4d93eb;
  margin-bottom: 15px;
}
.hero-s1__content .content-h {
  font-weight: 800;
  font-size: 56px;
  line-height: 65px;
  color: #ffffff;
  margin-bottom: 23px;
}
.hero-s1__content .content-d {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 55px;
  padding-right: 80px;
}

.hero-s1__thumb {
  padding-left: 35px;
}
.hero-s1__thumb .img {
  margin-right: -240px;
}

.hero-s2 {
  background: linear-gradient(45deg, #020006 0%, #050b47 100%);
  padding-top: 310px;
  padding-bottom: 90px;
  overflow: hidden;
}

.hero-s2__content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.hero-s2__content .content-sh {
  font-weight: 400;
  font-size: 25px;
  line-height: 31px;
  color: #8e74ff;
  margin-bottom: 32px;
}
.hero-s2__content .content-h {
  font-weight: 800;
  font-size: 80px;
  line-height: 100px;
  color: #ffffff;
  margin-bottom: 57px;
}

.hero-s2__thumb .img {
  position: relative;
  z-index: 9;
}
.hero-s2__thumb .img .b-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 1200px;
  height: 1200px;
}
.hero-s2__thumb .img .shp-1 {
  position: absolute;
  top: 0px;
  right: 160px;
  z-index: -1;
}
.hero-s2__thumb .img .shp-2 {
  position: absolute;
  right: 140px;
  top: 170px;
}

.hero-s4 {
  /* background: url("../images/backgrounds/hero-4-bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 177px 0 203px;
}

.hero-s4__content .content-sh {
  color: #da3095;
  font-size: 35px;
  line-height: 1.22;
}
.hero-s4__content .content-h {
  color: #fff;
  font-weight: 800;
  font-size: 60px;
  line-height: 1.33;
  margin-top: 20px;
  margin-bottom: 30px;
}
.hero-s4__content .content-d {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.77;
  margin-bottom: 70px;
}

.hero-s5 {
  /* background: radial-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../images/backgrounds/hero-5-bg.png") no-repeat top/cover; */
  padding: 243px 0 198px;
  margin-top: -50px;
  position: relative;
  z-index: 1;
}

.hero-s5__content {
  max-width: 794px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}
.hero-s5__content .content-sh {
  color: #fd4a18;
  font-size: 35px;
  line-height: 1.22;
  text-decoration: underline;
}
.hero-s5__content .content-h {
  color: #fff;
  font-weight: 800;
  font-size: 60px;
  line-height: 1.33;
  margin-top: 20px;
  margin-bottom: 30px;
}
.hero-s5__content .content-d {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.77;
  margin-bottom: 70px;
}
.hero-s5__content .btn-group {
  justify-content: center !important;
}

.hero-s6 {
  /* background: radial-gradient(
      83.72% 163.25% at 47.02% -6.69%,
      rgba(4, 61, 105, 0.95) 10.7%,
      rgba(0, 0, 63, 0.95) 71.01%,
      rgba(1, 18, 76, 0.95) 80.06%,
      rgba(2, 29, 83, 0.95) 89.65%,
      rgba(2, 33, 86, 0.95) 100%
    ),
    url("../images/backgrounds/hero-6-bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 130px;
}

.hero-s4__content .content-sh {
  color: #fff;
  font-size: 35px;
  line-height: 1.22;
}
.hero-s4__content .content-h {
  color: #fff;
  font-weight: 800;
  font-size: 60px;
  line-height: 1.33;
  margin-top: 20px;
  margin-bottom: 30px;
}
.hero-s4__content .content-d {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.77;
  margin-bottom: 70px;
}

.features-slider .slick-btn {
  position: absolute;
  top: -90px;
  right: 12px;
}
.features-slider .slick-btn.next {
  right: 82px;
}

.features-slider-s2 {
  background: #020314;
}

.features-slider-s3 {
  /* background: url(../images/backgrounds/feature5-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.features-slider-s3 .s-title-wrap {
  text-align: center;
}
.features-slider-s3 .s-title-wrap .s-sub-title {
  color: #fff !important;
}
.features-slider-s3 .s-title-wrap .s-title {
  color: #fff;
}
.features-slider-s3 .slick-dots {
  justify-content: center;
}
.features-slider-s3 .slick-dots li.slick-active {
  width: 33px;
  background: #fff;
}

.feature-home4 .content h4 a::before {
  background: linear-gradient(82.77deg, #770993 0%, #0f1755 95.38%) !important;
}
.feature-home4:hover .f-btn {
  color: #da3095;
}
.feature-home4:hover .f-btn svg,
.feature-home4:hover .f-btn path {
  color: #da3095;
}

.feature-home5 {
  position: relative;
}
.feature-home5 .content h4 a::before {
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}
.feature-home5 .content .f-btn {
  color: #1c1c1c;
}
.feature-home5 .content .f-btn svg,
.feature-home5 .content .f-btn path {
  color: #1c1c1c;
}
.feature-home5:hover .content h4 a {
  color: #fd4a18;
}
.feature-home5:hover .f-btn {
  color: #fd4a18;
}
.feature-home5:hover .f-btn svg,
.feature-home5:hover .f-btn path {
  color: #fd4a18;
}
.feature-home5::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 3px solid transparent;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.feature-home5:hover::after {
  border-bottom-color: #fd4a18;
}

.home6-features-sliders6 {
  /* background: url(../images/backgrounds/feature6-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home6-features-sliders6 .s-title-wrap {
  text-align: center;
}
.home6-features-sliders6 .s-title-wrap .s-sub-title {
  color: #fff !important;
}
.home6-features-sliders6 .s-title-wrap .s-title {
  color: #fff;
}
.home6-features-sliders6 .slick-dots {
  justify-content: center;
}
.home6-features-sliders6 .slick-dots li.slick-active {
  width: 33px;
  background: #fff;
}

.about-s1 .col-lg-6 {
  position: relative;
}
.about-s1 .col-lg-6 .bg {
  position: absolute;
  top: -70px;
  z-index: -1;
  width: auto;
  height: auto;
  left: -50%;
}
.about-s1 .col-lg-6 .bg img {
  width: auto;
  height: auto;
  max-width: none;
}

.about-s1__thumb {
  display: flex;
  margin-left: -260px;
  margin-right: 80px;
}
.about-s1__thumb > * {
  flex-basis: 50%;
  flex-shrink: 0;
}

.about-s1__thumb > .p-left {
  text-align: left;
}
.about-s1__thumb > .p-left img:last-child {
  margin-top: 26px;
}

.about-s1__thumb > .p-right {
  text-align: right;
  position: relative;
}
.about-s1__thumb > .p-right .box {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  text-align: center;
  position: absolute;
  width: 80%;
  left: 12%;
  border-radius: 5px;
  bottom: 40px;
}
.about-s1__thumb > .p-right .box h4 {
  margin-top: 10px;
  font-size: 35px;
  color: #fff;
  font-weight: 900;
  margin-bottom: 5px;
}
.about-s1__thumb > .p-right .box p {
  font-weight: 600;
  color: #fff;
}

.about-s1__content .s-title {
  margin-bottom: 33px;
}
.about-s1__content .desc {
  margin-bottom: 35px;
}

.about-s1__content > .content-b {
  display: flex;
  align-items: center;
  gap: 65px;
  border-top: 1px solid #dcdcdc;
  padding-top: 50px;
  margin-top: 34px;
}
.about-s1__content > .content-b .i-box {
  display: flex;
  align-items: center;
  gap: 35px;
}
.about-s1__content > .content-b .i-box p {
  margin: 0px;
  font-weight: 600;
  margin-top: 5px;
}

.about-s2 {
  background: #020314;
}

.about-s2__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.about-s2__content .desc {
  color: #b8b8b8;
  margin-top: 30px;
  margin-bottom: 30px;
}

.about-s2__content .content-b {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 55px;
}
.about-s2__content .content-b .i-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.about-s2__content .content-b .i-box .icon {
  width: 60px;
  height: 60px;
  background: #111446;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  flex-shrink: 0;
}
.about-s2__content .content-b .i-box p {
  font-family: "Mulish", serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #ffffff;
  margin: 0;
  padding-right: 20px;
}

.about-s3 {
  background: #020314;
  margin-top: -125px;
  padding-top: 250px;
  padding-bottom: 120px;
  position: relative;
  z-index: 9;
}
.about-s3 .about-s3__content {
  padding-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.about-s3 .about-s3__content .s-title {
  margin-bottom: 30px;
}
.about-s3 .shape img {
  position: absolute;
  top: 0px;
  height: 100%;
  bottom: 0px;
  z-index: -1;
}

.about-s4,
.about-s6 {
  padding-top: 10px;
}

.about-s4__thumb,
.about-s6__thumb,
.about-s5__thumb {
  display: flex;
  position: relative;
  margin-right: -105px;
  gap: 30px;
}
.about-s4__thumb .shape,
.about-s6__thumb .shape,
.about-s5__thumb .shape {
  position: absolute;
  z-index: -1;
  top: -10%;
}
.about-s4__thumb img,
.about-s6__thumb img,
.about-s5__thumb img {
  width: 100%;
  border-radius: 5px;
}
.about-s4__thumb > *,
.about-s6__thumb > *,
.about-s5__thumb > * {
  flex-basis: 50%;
}
.about-s4__thumb .p-left img:first-child,
.about-s6__thumb .p-left img:first-child,
.about-s5__thumb .p-left img:first-child {
  margin-bottom: 30px;
}
.about-s4__thumb .p-right,
.about-s6__thumb .p-right,
.about-s5__thumb .p-right {
  display: flex;
  align-items: center;
}

.about-s4__content,
.about-s6__content {
  padding-right: 125px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.about-s4__content .s-title,
.about-s6__content .s-title {
  margin-bottom: 30px;
}
.about-s4__content .desc,
.about-s6__content .desc {
  margin-bottom: 35px;
}
.about-s4__content .btn,
.about-s6__content .btn {
  box-shadow: 0px 12px 25px 8px rgba(0, 124, 251, 0.22);
}

.about-s4__content .content-b,
.about-s6__content .content-b {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 55px;
}
.about-s4__content .content-b .i-box,
.about-s6__content .content-b .i-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}
.about-s4__content .content-b .i-box .icon,
.about-s6__content .content-b .i-box .icon {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  flex-shrink: 0;
  box-shadow: 0px 5px 12px 2px rgba(0, 124, 251, 0.16);
}
.about-s4__content .content-b .i-box p,
.about-s6__content .content-b .i-box p {
  font-family: "Mulish", serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #1c1c1c;
  margin: 0;
}

.about-s5__thumb {
  margin-right: 0px;
  margin-left: -105px;
}
.about-s5__thumb .shape {
  left: -5%;
}

.about-s5__content {
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 110px;
}
.about-s5__content .s-title {
  margin-bottom: 30px;
}
.about-s5__content .progress-s1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.about-s6__thumb {
  margin-left: -105px;
  margin-right: 0px;
}
.about-s6__thumb .p-right {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.about-s6__thumb .p-right .box {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  text-align: center;
  position: relative;
  width: 100%;
  border-radius: 5px;
  margin-top: 30px;
}
.about-s6__thumb .p-right .box h4 {
  margin-top: 10px;
  font-size: 35px;
  color: #fff;
  font-weight: 900;
  margin-bottom: 5px;
}
.about-s6__thumb .p-right .box p {
  font-weight: 600;
  color: #fff;
}

.about-s6__content {
  padding-right: 0px;
  padding-left: 80px;
}
.about-s6__content .content-b {
  margin-bottom: 0px;
  padding-top: 50px;
  border-top: 1px solid;
  margin-top: 25px;
  gap: 50px;
  width: 100%;
}
.about-s6__content .content-b .btn {
  box-shadow: none;
  min-width: 173px;
  text-align: center;
}
.about-s6__content .content-b .i-box .icon {
  box-shadow: 0px 12px 20px 4px rgba(0, 0, 0, 0.1);
}

.about-s7 .about-s7__content,
.about-s7 .about-s8__content,
.about-s8 .about-s7__content,
.about-s8 .about-s8__content {
  padding-right: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.about-s7 .about-s7__content .info-list-s2,
.about-s7 .about-s8__content .info-list-s2,
.about-s8 .about-s7__content .info-list-s2,
.about-s8 .about-s8__content .info-list-s2 {
  margin-top: 35px;
}

.about-s7 .about-s7__thumb,
.about-s8 .about-s7__thumb {
  display: flex;
  gap: 30px;
  /* background: url(../images/backgrounds/about-4-shape.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: contain;
}
.about-s7 .about-s7__thumb *,
.about-s8 .about-s7__thumb * {
  flex-basis: 50%;
}
.about-s7 .about-s7__thumb .p-left img:first-child,
.about-s8 .about-s7__thumb .p-left img:first-child {
  margin-bottom: 30px;
}

.about-s8__thumb {
  position: relative;
}
.about-s8__thumb .shape {
  top: -3%;
  position: absolute;
  left: -3%;
  z-index: -1;
  width: 106%;
  height: 106%;
}
.about-s8__thumb .shape img {
  width: 100%;
}

.about-s9__content .content-b .i-box .icon {
  background-color: #da3095;
  box-shadow: 0 0 0 transparent;
}
.about-s9__content .btn {
  box-shadow: 0 0 0 transparent;
}

.about-s10__content .content-b .i-box .icon {
  box-shadow: 0px 5px 12px 2px rgba(61, 202, 177, 0.16);
}
.about-s10__content .btn {
  box-shadow: 0px 12px 25px 8px rgba(61, 202, 177, 0.2);
}

.about-s11__thumb {
  position: relative;
}
.about-s11__thumb img:nth-child(2) {
  position: absolute;
  top: -80px;
  right: 0;
}

.about-s11__content .founder-wrap {
  margin-top: 40px;
  max-width: 450px;
  padding: 15px 30px 15px 15px;
  background-color: #fff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.about-s11__content .founder-wrap .founder-identity .founder-img {
  border-radius: 5px;
}
.about-s11__content .founder-wrap .founder-identity .founder-name {
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}
.about-s11__content .founder-wrap .founder-identity .founder-name h4 {
  font-size: 18px;
  color: #fd4a18;
  line-height: 25px;
  margin-bottom: 4px;
}
.about-s11__content .founder-wrap .founder-identity .founder-name p {
  font-size: 14px;
  color: #535353;
  line-height: 25px;
}
.about-s11__content
  .founder-wrap
  .founder-identity
  .founder-name
  .founder-signature-img {
  display: none;
}

.about-s12__content {
  padding-left: 0;
}

.about-s12__thumb {
  margin-left: 0;
  position: relative;
}
.about-s12__thumb .skill-1 {
  max-width: 420px;
  position: absolute;
  top: -40px;
  right: 53px;
}

.contact-banner-s1 {
  /* background: url("../images/backgrounds/contact-style-1bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 0px;
  padding-top: 90px;
}

.contact-banner-s1__content {
  margin-top: 100px;
}
.contact-banner-s1__content .s-sub-title {
  color: #fff;
}
.contact-banner-s1__content .content-t {
  font-weight: 800;
  font-size: 35px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 32px;
}
.contact-banner-s1__content p {
  color: #fff;
  margin-bottom: 52px;
}
.contact-banner-s1__content .btn.btn-s1,
.contact-banner-s1__content .btn.btn-s2,
.contact-banner-s1__content .btn.btn-s3,
.contact-banner-s1__content .btn.btn-s4,
.contact-banner-s1__content .btn.btn-s5,
.contact-banner-s1__content .btn.btn-s6 {
  width: 170px;
  text-align: center;
}

.contact-banner-s2 {
  background: transparent;
  margin-top: -137px;
  z-index: 999;
  position: relative;
}

.contact-banner-s2 .contact-banner-inner {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 100px;
  border-radius: 5px;
}

.contact-banner-s2__content .content-t {
  font-weight: 700;
  font-size: 26px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 17px;
}
.contact-banner-s2__content p {
  color: #fff;
  margin-bottom: 0px;
}

.contact-banner-s2__button {
  text-align: right;
}
.contact-banner-s2__button .btn.btn-s2 {
  background: #fff;
  color: #000;
}
.contact-banner-s2__button .btn.btn-s2:hover {
  color: #000;
  background: #fff;
}

.contact-banner-s3 {
  /* background: url(../images/backgrounds/contact3-bg.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0px;
}

.contact-banner-s3 .content {
  text-align: center;
}
.contact-banner-s3 .content h2 {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  text-decoration-line: underline;
  color: #ffffff;
  margin-bottom: 60px;
  width: 52%;
  margin: auto;
}
.contact-banner-s3 .content .btn-group {
  justify-content: center;
  margin-top: 60px;
}

.contact-banner-s4 {
  /* background: url("../images/backgrounds/contact-style-2bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact-banner-s4 .contact-banner-s1__content .s-sub-title {
  color: #fff !important;
}

.feature-area-s1 {
  padding-bottom: 95px;
}
.feature-area-s1 .feature-s2,
.feature-area-s1 .feature-s7,
.feature-area-s1 .feature-s10 {
  margin-bottom: 25px;
}
.feature-area-s1 .feature-s2:hover .content h4 a,
.feature-area-s1 .feature-s7:hover .content h4 a,
.feature-area-s1 .feature-s10:hover .content h4 a,
.feature-area-s1 .feature-s2.active .content h4 a,
.feature-area-s1 .active.feature-s7 .content h4 a,
.feature-area-s1 .active.feature-s10 .content h4 a {
  color: #fff;
}

.feature-area-s2 .s-title-wrap {
  text-align: center;
}

.pricing-section-s1 {
  /* background: url(../images/backgrounds/pricing-bg.webp); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.pricing-section-s1 .s-title-wrap {
  text-align: center;
}

.pricing-section-s2 .s-title-wrap {
  text-align: center;
}
.pricing-section-s2 .pricing-tab-s1 .tab-links {
  margin-bottom: 50px;
}

.testimonial-area-s1 .testimonial-area-s1__content,
.testimonial-area-s1 .testimonial-area-s3__content,
.testimonial-area-s3 .testimonial-area-s1__content,
.testimonial-area-s3 .testimonial-area-s3__content {
  height: 100%;
  padding-top: 75px;
}
.testimonial-area-s1 .testimonial-area-s1__content .cont-h,
.testimonial-area-s1 .testimonial-area-s3__content .cont-h,
.testimonial-area-s3 .testimonial-area-s1__content .cont-h,
.testimonial-area-s3 .testimonial-area-s3__content .cont-h {
  margin-bottom: 30px;
}

.testimonial-area-s2 {
  background: #020314;
  position: relative;
  z-index: 9;
}
.testimonial-area-s2 .testimonial-area-s2__content {
  padding-right: 50px;
}
.testimonial-area-s2 .testimonial-area-s2__content .s-title {
  margin-bottom: 30px;
}
.testimonial-area-s2 .testimonial-area-s2__content .testimonial-s2 {
  padding-bottom: 50px;
}
.testimonial-area-s2 .testimonial-area-s2__content .testimonial-s2 .slick-btn {
  position: absolute;
  bottom: -53px;
  left: 0px;
  color: #fff;
  background: #090b34;
  opacity: 1;
}
.testimonial-area-s2
  .testimonial-area-s2__content
  .testimonial-s2
  .slick-btn:hover {
  background: #5132ef;
}
.testimonial-area-s2
  .testimonial-area-s2__content
  .testimonial-s2
  .slick-btn.prev {
  left: 75px;
}
.testimonial-area-s2 .shape img {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 100%;
  z-index: -1;
}

.testimonial-area-s3__content {
  padding-top: 0px !important;
  padding-right: 100px;
}

.testimonial-area-s3__facts {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
}
.testimonial-area-s3__facts > * {
  flex-basis: 50%;
}
.testimonial-area-s3__facts .p-left {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
}
.testimonial-area-s3__facts .p-left > * {
  flex-basis: 50%;
}
.testimonial-area-s3__facts .p-right {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding-bottom: 30px;
}
.testimonial-area-s3__facts .p-right > * {
  flex-basis: 50%;
}

.testimonial-area-s4 {
  background-color: #fff;
}
.testimonial-area-s4 .single-tes-s2 .desc {
  color: #535353;
}
.testimonial-area-s4 .single-tes-s2 .auth-info .details .name {
  color: #1c1c1c;
}
.testimonial-area-s4 .single-tes-s2 .auth-info .details .designation {
  color: #535353;
}
.testimonial-area-s4 .testimonial-area-s2__content .testimonial-s2 .slick-btn {
  position: absolute;
  bottom: -53px;
  left: 0px;
  color: #fff;
  background: linear-gradient(82.77deg, #770993 0%, #0f1755 95.38%);
  opacity: 1;
}
.testimonial-area-s4
  .testimonial-area-s2__content
  .testimonial-s2
  .slick-btn:hover {
  background: linear-gradient(
    82.77deg,
    rgba(15, 23, 85, 0.3),
    rgba(119, 9, 147, 0.4)
  );
}

.testimonial-area-s1
  .testimonial-area-s2__content
  .testimonial-s2
  .auth-info
  .thumb,
.testimonial-area-s2
  .testimonial-area-s2__content
  .testimonial-s2
  .auth-info
  .thumb,
.testimonial-area-s3
  .testimonial-area-s2__content
  .testimonial-s2
  .auth-info
  .thumb,
.testimonial-area-s4
  .testimonial-area-s2__content
  .testimonial-s2
  .auth-info
  .thumb {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}
.testimonial-area-s1
  .testimonial-area-s2__content
  .testimonial-s2
  .auth-info
  .thumb
  img,
.testimonial-area-s2
  .testimonial-area-s2__content
  .testimonial-s2
  .auth-info
  .thumb
  img,
.testimonial-area-s3
  .testimonial-area-s2__content
  .testimonial-s2
  .auth-info
  .thumb
  img,
.testimonial-area-s4
  .testimonial-area-s2__content
  .testimonial-s2
  .auth-info
  .thumb
  img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}

.funfact-area-s1 {
  /* background: url(../images/backgrounds/funfact-bg.webp); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 260px;
  padding-bottom: 150px;
}
.funfact-area-s1 .video-thumb {
  margin-top: -280px;
  margin-bottom: 90px;
}

.funfact-area-s2 {
  margin-top: -105px;
  position: relative;
  z-index: 999;
}

.funfact-area-s2-container {
  padding: 65px 12px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 12px 35px rgba(0, 124, 251, 0.16);
}

.funfact-area-s6-container {
  box-shadow: 0px 12px 35px rgba(61, 202, 177, 0.16);
}

.funfact-area-s1 {
  /* background: url(../images/backgrounds/funfact-bg.webp); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 260px;
  padding-bottom: 150px;
}
.funfact-area-s1 .video-thumb {
  margin-top: -280px;
  margin-bottom: 90px;
}

.funfact-area-s2 {
  margin-top: -105px;
  position: relative;
  z-index: 999;
}

.funfact-area-s2-container {
  padding: 65px 12px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  box-shadow: 0px 12px 35px rgba(0, 124, 251, 0.16);
}

.funfact-area-s6-container {
  box-shadow: 0px 12px 35px rgba(61, 202, 177, 0.16);
}

.blog-area-s1,
.blog-area-s2 {
  padding-bottom: 120px;
}

.blog-area-s2 {
  background: #020314;
}
.blog-area-s2 .s-title-wrap {
  text-align: center;
}

.project-slider-area-s1 {
  background: linear-gradient(45deg, #020315 0%, #050b47 100%);
  padding-top: 110px;
  padding-bottom: 260px;
}
.project-slider-area-s1 .s-title-wrap {
  text-align: center;
}
.project-slider-area-s1 .slick-dots {
  justify-content: center;
}
.project-slider-area-s1 .slick-dots li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #232767;
}
.project-slider-area-s1 .slick-dots li.slick-active {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}

.project-slider-area-s1 .slick-track {
  margin-left: -16%;
}

.project-slider-area-s2 .slick-dots {
  justify-content: center;
}

.project-slider-area-s3 {
  padding-top: 110px;
  padding-bottom: 120px;
  background: linear-gradient(
    82.77deg,
    rgba(119, 9, 147, 0.1),
    rgba(15, 23, 85, 0.1)
  );
}
.project-slider-area-s3 .slick-dots {
  justify-content: center;
}
.project-slider-area-s3 .slick-dots li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: linear-gradient(82.77deg, #770993 0%, #0f1755 95.38%);
  opacity: 0.3;
}
.project-slider-area-s3 .slick-dots li.slick-active {
  background: linear-gradient(82.77deg, #770993 0%, #0f1755 95.38%);
  opacity: 1;
}

.slider-project-s1 .content {
  background: #da3095;
}

.project-slider-area-s6 .slick-dots {
  justify-content: center;
}
.project-slider-area-s6 .slick-dots li {
  width: 20px;
  height: 6px;
  background: linear-gradient(
    89.96deg,
    rgba(49, 47, 249, 0.3) 0%,
    rgba(133, 7, 255, 0.3)
  );
  border-radius: 100px;
}
.project-slider-area-s6 .slick-dots li.slick-active {
  width: 33px;
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}

.team-area-s1 {
  background: linear-gradient(45deg, #020006 0%, #050b47 100%);
}
.team-area-s1 .s-title-wrap {
  text-align: center;
}

.team-area-s2 {
  /* background: url(../images/backgrounds/about-team-bg.jpg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.team-area-s2 .s-title-wrap {
  text-align: center;
}
.team-area-s2 .team-slider-s1 .slick-dots li {
  background: linear-gradient(
    42.74deg,
    rgba(93, 64, 250, 0.3) 6.11%,
    rgba(78, 149, 237, 0.3) 90.69%
  );
}
.team-area-s2 .team-slider-s1 .slick-dots li.slick-active {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}

.service-detail-main {
  padding-right: 18px;
}
.service-detail-main .feature-img {
  margin-bottom: 40px;
}
.service-detail-main .feature-img img {
  width: 100%;
}
.service-detail-main .title {
  font-weight: 800;
  font-size: 26px;
  line-height: 33px;
  color: #1c1c1c;
  margin-bottom: 20px;
}
.service-detail-main .title:hover {
  text-decoration: underline;
}
.service-detail-main .desc {
  margin-bottom: 20px;
}
.service-detail-main .img-group {
  display: flex;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 40px;
}
.service-detail-main .img-group img {
  border-radius: 5px;
  height: 330px;
  width: auto;
  object-fit: cover;
}
.service-detail-main .title-2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 20px;
}
.service-detail-main .s-box-cont {
  padding-top: 10px;
}

.service-detail-side .service-c-list {
  background: #ffffff;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  padding: 40px;
  margin-bottom: 40px;
}
.service-detail-side .service-c-list .title {
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 20px;
}

.service-detail-side .service-c-list .c-list li {
  padding: 10px 20px;
  background: #ecf5ff;
  border-radius: 5px;
  margin-bottom: 15px;
}
.service-detail-side .service-c-list .c-list li a .icon {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}
.service-detail-side .service-c-list .c-list li a .icon img {
  width: 17px;
  height: auto;
  object-fit: cover;
}
.service-detail-side .service-c-list .c-list li a .text {
  font-family: "Mulish", serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  display: inline-block;
  align-items: center;
  margin-left: 15px;
  color: #1c1c1c;
}
.service-detail-side .service-c-list .c-list li:hover {
  background: linear-gradient(45deg, #5d40fa 0%, #4e95ed 100%);
}
.service-detail-side .service-c-list .c-list li:hover a .text {
  color: #fff;
}
.service-detail-side .service-c-list .c-list li:hover a .icon {
  background: #fff;
}
.service-detail-side .service-c-list .c-list li:hover a .icon img {
  filter: brightness(0%);
}

.faq-section-s1 {
  background: #e1eff8;
}
.faq-section-s1 .s-title-wrap {
  text-align: center;
}

.t-member-details__container {
  display: flex;
}
.t-member-details__container > * {
  flex-grow: 0;
  flex-shrink: 0;
}
.t-member-details__container .member-thumb {
  flex-basis: 37%;
}
.t-member-details__container .member-thumb img {
  width: 100%;
}
.t-member-details__container .member-stats {
  flex-basis: 26%;
}

.t-member-details__container .member-info {
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  flex-basis: 37%;
  padding-right: 80px;
  padding-left: 40px;
  padding-top: 50px;
}
.t-member-details__container .member-info .m-name {
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #1c1c1c;
  margin-bottom: 5px;
}
.t-member-details__container .member-info .m-desig {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #535353;
  display: inline-block;
  margin-bottom: 20px;
}
.t-member-details__container .member-info .m-info-list {
  margin-bottom: 18px;
}
.t-member-details__container .member-info .m-info-list li {
  display: flex;
  justify-content: space-between;
}
.t-member-details__container .member-info .m-info-list li li > * {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
}
.t-member-details__container .member-info .social-icons-s1,
.t-member-details__container .member-info .social-icons-s4,
.t-member-details__container .member-info .social-icons-s6 {
  padding-top: 25px;
  border-top: 1px solid #dcdcdc;
}
.t-member-details__container .member-info .social-icons-s1 li a,
.t-member-details__container .member-info .social-icons-s4 li a,
.t-member-details__container .member-info .social-icons-s6 li a {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
}

.t-member-details__container .member-stats {
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding-left: 80px;
  padding-right: 40px;
  padding-top: 38px;
  padding-bottom: 50px;
}

.t-member-details__container .member-stats .funfact-s2 {
  font-size: 44px;
  border-bottom: 1px solid #dcdcdc;
  padding-top: 12px;
  padding-bottom: 13px;
}
.t-member-details__container .member-stats .funfact-s2 .icon {
  width: auto;
  height: auto;
  box-shadow: none;
  line-height: 1em;
}
.t-member-details__container .member-stats .funfact-s2 .icon img {
  width: 44px;
}
.t-member-details__container .member-stats .funfact-s2 .content h4 {
  margin-bottom: 3px;
  color: #1c1c1c;
}
.t-member-details__container .member-stats .funfact-s2 .content p {
  line-height: 1.1em;
}

.member-about__desc {
  padding-right: 50px;
}
.member-about__desc .s-title-wrap {
  margin-bottom: 30px;
}
.member-about__desc .desc {
  margin-bottom: 30px;
}
.member-about__desc .desc:last-child {
  margin-bottom: 0;
}

.member-about__skills {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.member-about__skills .progress-s1 {
  width: 100%;
}
.member-about__skills .progress-s1 .s-progress {
  margin-bottom: 22px;
}
.member-about__skills .progress-s1 .s-progress:last-child {
  margin-bottom: 0px;
}

.member-achievement__container {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}
.member-achievement__container * {
  flex-basis: 20%;
}
.member-achievement__container .box {
  background: #ffffff;
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  text-align: center;
  padding: 35px 0px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.member-achievement__container .box:hover {
  transform: translateY(-10px);
}

.portfolio-detail__main {
  padding-right: 50px;
}
.portfolio-detail__main .feature-img img {
  width: 100%;
}
.portfolio-detail__main .p-title {
  font-size: 30px;
  line-height: 45px;
  color: #1c1c1c;
  margin-bottom: 20px;
  margin-top: 40px;
}
.portfolio-detail__main .image-slider-s1 {
  margin-top: 5px;
  margin-left: -12px;
  margin-right: -12px;
}
.portfolio-detail__main .image-slider-s1 .item {
  padding: 0px 10px;
}
.portfolio-detail__main .image-slider-s1 .item img {
  width: 100%;
}
.portfolio-detail__main .image-slider-s1 .slick-dots {
  justify-content: center;
}
.portfolio-detail__main .image-slider-s1 .slick-dots li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: linear-gradient(
    138.5deg,
    rgba(80, 59, 238, 0.3) 7.14%,
    rgba(76, 138, 235, 0.3) 93.14%
  );
}
.portfolio-detail__main .image-slider-s1 .slick-dots li.slick-active {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}

.portfolio-detail__main .image-slider-s1 {
  margin-top: 5px;
  margin-left: -12px;
  margin-right: -12px;
}
.portfolio-detail__main .image-slider-s1 .item {
  padding: 0px 10px;
}
.portfolio-detail__main .image-slider-s1 .item img {
  width: 100%;
}
.portfolio-detail__main .image-slider-s1 .slick-dots {
  justify-content: center;
  margin-top: 30px;
}
.portfolio-detail__main .image-slider-s1 .slick-dots li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: linear-gradient(
    138.5deg,
    rgba(80, 59, 238, 0.3) 7.14%,
    rgba(76, 138, 235, 0.3) 93.14%
  );
}
.portfolio-detail__main .image-slider-s1 .slick-dots li.slick-active {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
}

.portfolio-detail__main .portfolio-challenges {
  margin-top: 40px;
}
.portfolio-detail__main .portfolio-challenges .title {
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 20px;
}
.portfolio-detail__main .portfolio-challenges .desc {
  margin-bottom: 30px;
}
.portfolio-detail__main .portfolio-challenges .i-list li .text {
  color: #1c1c1c;
}

.portfolio-detail__side .title {
  font-size: 26px;
  line-height: 33px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dcdcdc;
}
.portfolio-detail__side .widget {
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.portfolio-detail__side .p-detail-box .details {
  margin-top: 30px;
}
.portfolio-detail__side .p-detail-box .details li {
  margin-bottom: 19px;
}
.portfolio-detail__side .p-detail-box .details li .name {
  font-weight: 500;
  font-size: 16px;
  color: #1c1c1c;
  display: inline-block;
  margin-right: 23px;
}
.portfolio-detail__side .p-detail-box .details li:last-child {
  margin-bottom: 0px;
}

.contact-detail-box {
  margin-top: 30px;
}
.contact-detail-box .info-list {
  margin-top: 30px;
}
.contact-detail-box .info-list li a {
  color: #535353;
}
.contact-detail-box .info-list li a svg {
  color: #000060;
}

body#home-2 {
  background-color: #020314;
}
body#home-2 .s-sub-title {
  color: #8e74ff;
}
body#home-2 .s-title {
  color: #fff;
}
body#home-2 #cursor {
  width: 35px;
  height: 35px;
  top: -20px;
  position: fixed;
  border-radius: 50%;
  transform: translate(-40%, -35%);
  opacity: 0.5;
  z-index: 9999;
  transition: all 0.15s ease;
  pointer-events: none;
  animation: pulsingShadow 2s ease-in-out 0s infinite alternate;
}
body#home-2 .mobile-header {
  background: #050b47;
  border-bottom: 1px solid;
}

@keyframes pulsingShadow {
  0% {
    box-shadow: inset 0 0 0 40px #0b0d10;
    transform: translate(-40%, -35%) scale(1);
  }
  25% {
    box-shadow: inset 0 0 0 40px #007cfb;
  }
  50% {
    box-shadow: inset 0 0 0 40px #4d93eb;
  }
  100% {
    box-shadow: inset 0 0 0 40px #fff;
    transform: translate(-40%, -35%) scale(1.15);
  }
}
#home-3 .s-sub-title {
  color: #007cfb;
}
#home-3 .features-slider-s3 {
  padding-bottom: 225px;
}
#home-3 .testimonial-area-s1,
#home-3 .testimonial-area-s3 {
  /* background: url(../images/backgrounds/background-testimonial.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home-3 .blog-area-s1 .s-title-wrap {
  text-align: center;
}

body#home-1 .mobile-header {
  background: #000060;
}

#home-4 .s-sub-title {
  color: #da3095;
}
#home-4 .home4-hero-btn {
  background-color: #da3095;
  color: #fff;
  border: 2px solid #fff;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
#home-4 .home4-hero-btn:hover {
  background-color: #fff;
  color: #da3095;
}

#home-5 .top-content-sec {
  position: relative;
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}
#home-5 .top-content-sec::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 32%;
  height: 100%;
  background-color: #8236ff;
  z-index: 1;
  border-radius: 500px;
}
#home-5 .top-content-sec .top-content-container {
  position: relative;
  z-index: 11;
  max-width: 1626px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
#home-5 .top-content-sec .top-content-container .top-content-left p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  color: #fff;
}
#home-5 .top-content-sec .top-content-container .top-content-left p a {
  color: #fd4a18;
  text-decoration: underline;
}
#home-5 .top-content-sec .top-content-container .top-content-right {
  padding-top: 12px;
  padding-bottom: 12px;
}
#home-5 .top-content-sec .top-content-container .top-content-right .call-list {
  margin-right: 60px;
}
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .call-list
  li
  a
  span {
  color: #fff;
}
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s1,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s4,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s6 {
  text-align: right;
}
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s1
  li
  a,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s4
  li
  a,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s6
  li
  a {
  display: flex;
  align-items: center;
  justify-content: center;
}
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s1
  li
  a::before,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s4
  li
  a::before,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s6
  li
  a::before {
  box-shadow: inset 0 0 0 0 transparent;
}
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s1
  li
  a:hover,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s4
  li
  a:hover,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s6
  li
  a:hover {
  transform: translateY(-3px);
}
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s1
  li
  a:hover
  svg,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s4
  li
  a:hover
  svg,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s6
  li
  a:hover
  svg {
  color: #fff;
}
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s1
  li
  a:hover::before,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s4
  li
  a:hover::before,
#home-5
  .top-content-sec
  .top-content-container
  .top-content-right
  .social-icons-s6
  li
  a:hover::before {
  box-shadow: inset 0 0 50px transparent;
}
#home-5 .s-sub-title {
  color: #fd4a18;
}
#home-5 .home5-features-slider {
  padding-bottom: 200px;
}
#home-5 .home5-feature-area-s5 {
  /* background-image: linear-gradient(
      89.96deg,
      rgba(49, 47, 249, 0.9) 0%,
      rgba(133, 7, 255, 0.9)
    ),
    url("../images/backgrounds/service-bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home-5 .home5-feature-area-s5 .s-title-wrap > * {
  color: #fff;
}
#home-5 .skill-section {
  /* background-image: url("../images/backgrounds/skill-bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home-5 .home5-testimonial-area-s5 {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("../images/backgrounds/testimonial-bg.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home-5 .home5-testimonial-area-s5 .testimonial-area-s1__content .cont-h h2,
#home-5 .home5-testimonial-area-s5 .testimonial-area-s3__content .cont-h h2 {
  color: #fff;
}
#home-5 .home5-testimonial-area-s5 .slick-dots {
  justify-content: flex-start;
}
#home-5 .home5-testimonial-area-s5 .slick-dots li {
  width: 20px;
  height: 6px;
  background: linear-gradient(
    89.96deg,
    rgba(49, 47, 249, 0.3) 0%,
    rgba(133, 7, 255, 0.3)
  );
  border-radius: 100px;
}
#home-5 .home5-testimonial-area-s5 .slick-dots li.slick-active {
  width: 33px;
  background: linear-gradient(89.96deg, #312ff9 0%, #8507ff 100%);
}

#home-6 .s-sub-title {
  color: #1c1c1c;
}
#home-6 .hero-s6 .img {
  position: relative;
}
#home-6 .hero-s6 .img .world-map {
  position: absolute;
  top: 0;
  left: 50px;
  z-index: -1;
  animation: rotate 11s infinite linear;
}
#home-6 .home6-features-slider {
  position: relative;
  z-index: 999;
  margin-top: -150px;
}
#home-6 .features-slider-s3 {
  padding-bottom: 225px;
}
#home-6 .home6-team-s {
  /* background-image: url("../images/backgrounds/team-bg6.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
body#service .feature-area-s1 {
  padding-top: 110px;
  padding-bottom: 90px;
}
body#service .feature-area-s1 .s-title-wrap {
  text-align: center;
}

body#team .team-area-s2 {
  padding-bottom: 90px;
}
body#team .team-area-s2 .row {
  margin-bottom: 30px;
}
body#team .team-area-s2 .row .single-team-s2 {
  padding: 0px;
}

body#blog .b-post-s1,
body#blog .b-post-s2,
body#blog .b-post-s4,
body#blog .b-post-s6 {
  margin: 12px 0px;
}
body#blog .b-pagination {
  padding-top: 40px;
}

.blog-main .b-post-s1,
.blog-main .b-post-s2,
.blog-main .b-post-s4,
.blog-main .b-post-s6 {
  margin-bottom: 25px;
}
.blog-main .b-pagination {
  padding-top: 25px;
}

.blog-sidebar-s1 .widget {
  background: #ffffff;
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 30px;
}
.blog-sidebar-s1 .widget .w-title {
  font-size: 26px;
  line-height: 35px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.featured-author-widget {
  text-align: center;
}
.featured-author-widget .content .name {
  font-family: "Poppins", serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  color: #1c1c1c;
  margin-top: 40px;
  margin-bottom: 15px;
}
.featured-author-widget .content .name p {
  margin-bottom: 35px;
}
.featured-author-widget .content .social-icons-s1 li a,
.featured-author-widget .content .social-icons-s4 li a,
.featured-author-widget .content .social-icons-s6 li a {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
}

.search-widget .search-container {
  background: #ecf5ff;
  border-radius: 5px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.search-widget .search-container input[type="text"] {
  border: none;
  background: none;
  outline: none;
  color: #8eadd0;
}

.search-widget .search-container button {
  border: none;
  outline: none;
  background: none;
  color: #000060;
  font-size: 25px;
}

.latest-posts-widget .recent-posts .rp-single .thumb img {
  width: 85px;
  height: 92px;
  object-fit: cover;
}
.latest-posts-widget .recent-posts .rp-single .content h4 {
  color: #1c1c1c;
}
.latest-posts-widget .recent-posts .rp-single .content span {
  color: #535353;
}
.latest-posts-widget .recent-posts .rp-single:last-child {
  margin-bottom: 0px;
}

.blog-catagories-widget .catagory-list li {
  margin-bottom: 15px;
}
.blog-catagories-widget .catagory-list li:last-child {
  margin-bottom: 0;
}
.blog-catagories-widget .catagory-list li a {
  display: flex;
  justify-content: space-between;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.blog-catagories-widget .catagory-list li a:hover {
  color: #007cfb;
}

.popular-tags-widget .tags-container .tag {
  line-height: 24px;
  background: #ecf5ff;
  border-radius: 5px;
  display: inline-block;
  padding: 11px 20px;
  margin-right: 5px;
  margin-bottom: 8px;
}
.popular-tags-widget .tags-container .tag:last-child {
  margin-right: 0px;
}
.popular-tags-widget .tags-container .tag:hover {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
}

.subs-newsletter-widget {
  /* background: url(../images/backgrounds/subs-background.png) !important; */
  position: relative;
  z-index: 9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.subs-newsletter-widget .w-title {
  color: #fff;
}
.subs-newsletter-widget p {
  color: #fff;
}
.subs-newsletter-widget::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  background: rgba(0, 0, 0, 0.6);
  z-index: -1;
  border-radius: 5px;
  left: 0;
  top: 0;
}

.subs-newsletter-widget form input[type="text"],
.subs-newsletter-widget form button {
  height: 60px;
  border: 0;
  display: block;
  width: 100%;
  outline: 0;
  border-radius: 5px;
}
.subs-newsletter-widget form input[type="text"] {
  padding: 0 30px;
}
.subs-newsletter-widget form input[type="text"]::placeholder {
  color: #909090;
}
.subs-newsletter-widget form button {
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #fff;
  background: linear-gradient(
    88.02deg,
    rgba(91, 75, 248, 0.9) 0%,
    rgba(79, 142, 237, 0.9) 99.75%
  );
  border-radius: 5px;
  margin-top: 15px;
}

.contact-form-area .contact-info {
  padding-right: 105px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.contact-form-area .contact-info .s-title {
  margin-bottom: 50px;
}

.c-info-box {
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  padding: 30px 25px;
  margin-bottom: 30px;
}
.c-info-box h4 {
  font-size: 18px;
  line-height: 35px;
}
.c-info-box:last-child {
  margin-bottom: 0px;
}

.map-section iframe {
  width: 100%;
  margin-bottom: -10px;
}

.not-found .thumb {
  text-align: center;
  margin-bottom: 100px;
}
.not-found .content {
  text-align: center;
}
.not-found .content .s-title {
  margin-bottom: 0px;
}
.not-found .content .btn {
  margin-top: 30px;
}

.single-blog-content {
  padding-right: 50px;
}
.single-blog-content .feature-img {
  margin-bottom: 30px;
}

.single-blog-content .blog-meta li {
  display: inline-block;
  margin-right: 40px;
}
.single-blog-content .blog-meta li a {
  font-size: 14px;
  line-height: 21px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.single-blog-content .blog-meta li a .icon {
  color: #007cfb;
  margin-right: 10px;
}
.single-blog-content .blog-meta li a:hover {
  color: #007cfb;
}

.single-blog-content .post-title {
  font-size: 40px;
  line-height: 50px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.single-blog-content .desc {
  margin-bottom: 25px;
}

.single-blog-content .author-card {
  background: #ecf5ff;
  padding: 30px;
  display: flex;
  gap: 20px;
  border-radius: 5px;
}
.single-blog-content .author-card .thumb {
  flex-shrink: 0;
}
.single-blog-content .author-card .content p {
  color: #1c1c1c;
}
.single-blog-content .author-card .content .a-name-desig-wrap {
  display: flex;
  align-items: baseline;
  gap: 20px;
}
.single-blog-content .author-card .content .a-name-desig-wrap .a-desig {
  margin: 0;
}
.single-blog-content .author-card .content .a-name-desig-wrap .a-name {
  font-size: 20px;
  line-height: 25px;
  color: #007cfb;
}

.single-blog-content .image-slider-s1 {
  margin-top: 30px;
  margin-right: -12px;
  margin-left: -12px;
}
.single-blog-content .image-slider-s1 .item {
  padding: 0 12px;
}
.single-blog-content .image-slider-s1 .item img {
  margin: 0;
  width: 100%;
}

.single-blog-content .image-slider-s1 .slick-dots {
  justify-content: center;
  margin-top: 30px;
}
.single-blog-content .image-slider-s1 .slick-dots li {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: linear-gradient(
    138.5deg,
    rgba(80, 59, 238, 0.3) 7.14%,
    rgba(76, 138, 235, 0.3) 93.14%
  );
}
.single-blog-content .image-slider-s1 .slick-dots li.slick-active {
  background: linear-gradient(138.5deg, #503bee 7.14%, #4c8aeb 93.14%);
  width: 14px;
  height: 14px;
}

.single-blog-content .title-2 {
  font-size: 30px;
  line-height: 45px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.single-blog-content .i-list li .text {
  color: #1c1c1c;
}

.s-post-tag-share-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  border-top: 1px solid #dcdcdc;
  margin-top: 40px;
}

.s-post-tag-share-wrap .title {
  font-size: 26px;
  line-height: 45px;
}

.s-post-tag-share-wrap .p-tags {
  display: flex;
  align-items: baseline;
  gap: 25px;
}
.s-post-tag-share-wrap .p-tags li {
  display: inline-block;
  margin-right: 15px;
}

.s-post-tag-share-wrap .p-share {
  display: flex;
  align-items: center;
  gap: 25px;
}
.s-post-tag-share-wrap .p-share .social-icons-s1 li a,
.s-post-tag-share-wrap .p-share .social-icons-s4 li a,
.s-post-tag-share-wrap .p-share .social-icons-s6 li a {
  background: linear-gradient(
    45deg,
    rgb(94, 64, 251) 0%,
    rgb(78, 149, 237) 100%
  );
  color: #fff;
}

.more-post-section {
  display: flex;
  margin: 0 -12px;
  margin-top: 110px;
}
.more-post-section > * {
  width: 50%;
}

.more-post-section .post {
  position: relative;
  padding: 0 12px;
}
.more-post-section .post .thumb {
  width: 100%;
}
.more-post-section .post .content {
  position: absolute;
  top: 0px;
  text-align: center;
  padding: 0 60px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.more-post-section .post .content p {
  font-family: "Mulish", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #fff;
}
.more-post-section .post .content a {
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.more-post-section .post .content a:hover {
  color: #007cfb;
}

.more-post-section .post.prev a svg {
  margin-right: 10px;
}

.more-post-section .post.next a svg {
  margin-left: 10px;
}

.comments-section {
  margin-top: 120px;
}

.comments-section .title {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 30px;
}

.s-comment .s-comment {
  padding-left: 60px;
}

.s-comment-inner {
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #dcdcdc;
}

.s-comment-inner .thumb {
  flex-shrink: 0;
}

.s-comment-inner .content .name {
  font-size: 26px;
  line-height: 33px;
  margin-bottom: 10px;
}
.s-comment-inner .content .c-massege {
  margin-bottom: 8px;
}
.s-comment-inner .content .c-date {
  font-size: 14px;
  color: #007cfb;
}
.s-comment-inner .content .r-btn {
  font-size: 14px;
  color: #007cfb;
  display: inline-block;
  margin-left: 30px;
}

.comment-form {
  margin-top: 110px;
}

.comment-form h2 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 20px;
}

.comment-form p {
  margin: 0;
}

.comment-form form {
  margin-top: 50px;
}
.comment-form form input,
.comment-form form textarea,
.comment-form form button {
  border: 0;
  outline: 0;
}

.comment-form form input {
  background: #ecf5ff;
  display: inline-block;
  height: 66px;
  padding: 0 30px;
  border-radius: 33px;
}
.comment-form form input::placeholder {
  color: #8eadd0;
}

.comment-form textarea {
  display: inline-block;
  width: 100%;
  background: #ecf5ff;
  border-radius: 33px;
  padding: 30px 30px 0px;
}
.comment-form textarea::placeholder {
  color: #8eadd0;
}

.comment-form .input-group {
  display: flex;
  margin-bottom: 30px;
  gap: 30px;
}
.comment-form .input-group > * {
  flex-basis: 50%;
}

.comment-form button {
  width: 185px;
  height: 66px;
  display: inline-block;
  margin-top: 40px;
  background: linear-gradient(81.44deg, #5c46f9 5.87%, #4e92ed 92.48%);
  border-radius: 33px;
  color: #ffffff;
}


/* *********************************** */
/* Begining of development CSS section */
/* *********************************** */

.home-container a {
  font-size: 16px;
  line-height: inherit;
  color: inherit;
  text-decoration: inherit;
  transition: inherit;
}

.home-container a:hover {
  /*color: inherit;*/
  opacity: 0.9;
  text-decoration: inherit;
}

.container {
  max-width: 1194px;
  margin-left: auto;
  margin-right: auto;
}

.header {
  position: fixed;
  top: 0;
  background-color: #810ed3;
  z-index: 99;
  width: 100%;
  line-height: 26px;
  border-bottom: 1px solid rgb(102, 102, 102, 0.2);
  white-space: nowrap;
}

.header .header-layout {
  padding: 20px 0;
  font-size: 16px;
  font-weight: 600;
  color: white;
}


.header .icon {
  float: left;
  margin-right: 4%;
  margin-left: 5%;
  margin-bottom: 15px;
  width: 65px;
  height: 65px;
}

.header .small-icon {
  display: none;
  width: 35px;
  height: 35px;
}

.header .menus {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  /*padding-top: 5px;*/
  /*float: left;*/
  /*width: 44%;*/
  margin-top: 20px;
}

.header .menus a {
  white-space: nowrap;
  margin-right: 6%;
}

.header .menus a:hover {
  cursor: pointer;
  opacity: 0.7;
}

.header .menus span {
  white-space: nowrap;
  margin-right: 6%;
}

.header .menus span:hover {
  cursor: pointer;
  opacity: 0.7;
}

.header .dropdown {
  float: left;
  margin-right: 6%;
}

.header .dropdown .divider {
  border-top: 3px solid #810ed3;
}

.header .dropdown .hoverable {
  display: flex;
  align-items: center;
  margin-right: 4%;
}

.header .dropdown .hoverable .chevronDownIcon {
  margin-left: 4px;
}

.header .dropdown .hoverable .chevronDownIcon:hover {
  cursor: pointer;
  color: #5f5cd7;
}

.header .dropdown:hover .dropdown-menu {
  display: block;
}

.header .dropdown-menu {
  padding-top: 20px;
  display: none;
  z-index: 9999;
  position: absolute;
}

.header .dropdown-menu .menu-layout {
  padding: 1rem;
  background-color: #ffffff;
  border-left: 1px solid rgb(102, 102, 102, 0.1);
  border-right: 1px solid rgb(102, 102, 102, 0.1);
  border-bottom: 1px solid rgb(102, 102, 102, 0.1);
  box-shadow: 5px 5px 50px 5px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.header .dropdown-menu span {
  color: rgba(0, 0, 0, 0.6)
}

.header .buttons {
  display: flex;
  gap: 20px;
  margin-left: auto;
  margin-right: 2rem;
}

.header .buttons .linkButton {
  cursor: pointer;
  opacity: 0.9;
}

.header .login a {
  width: 100px;
  height: 36px;
  background-color: #FF9700;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  padding-top: 0.2rem;
  color: white;
  line-height: 31px;
  margin-top: -15px;
}

.header .drawer-button {
  display: none;
}

.home-drawer a {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: inherit;
  transition: inherit;
}

.container-wrapper {
  padding-left: 50px;
}

.row {
  display: flex;
  flex-direction: row;
}

.hero {
  padding-top: 180px;
  padding-bottom: 100px;
}

.hero .layout {
  display: flex;
}

.hero .layout .left {
  width: 50%;
  padding-right: 5%;
}

.hero .layout .right {
  width: 50%
}

.hero .layout .right .sub-title {
  font-weight: 800;
  font-size: 45px;
  line-height: 55px;
  color: #1c1c1c;
  margin-top: 50px;
  margin-bottom: 30px;
}

.hero .layout .right .content {
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #6b757c;
  margin-bottom: 70px;
}

.features {
  text-align: center;
  background-color: #f4f4f4;
  padding-top: 67px;
  padding-bottom: 50px;
}

.features .title-wrap {
  margin-bottom: 80px;
}

.features .sub-title {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
  letter-spacing: 1px;
  color: #810ed3;
}

.features .sub-text {
  font-size: 16px;
  font-weight: 500;
  color: #6b757c;
}

.features .title {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
}

.features .feature {
  flex-basis: 50%;
  padding-right: 50px;
}

.features .feature h3 {
  font-size: 23px;
  font-weight: 900;
  line-height: 23px;
  padding-top: 25px;
  padding-bottom: 15px;
}

.features .feature p {
  color: #666;
  font-size: 16px;
  font-weight: 500;
  line-height: 31px;
}

.features .feature .data-extraction {
  border-radius: 8px;
  background-color: rgba(214,68,160,.1);
  box-shadow: 0 12px 14px -12px rgba(214,68,160,.46);
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 45px;
  padding-right: 45px;
}

.data-extraction-demo a {
  width: 184px;
  height: 60px;
  background-color: #d644a0;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #dcdcdc;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  position: relative;
  top: -30px;
  padding-top: 0.8rem;
  margin-right: 20px;
  color: white;
  line-height: 31px;
  font-size: 18px;
  font-weight: 500;
}

.features .feature .data-extraction h3 {
  color: #d644a0;
}

.features .feature .document-classification {
  border-radius: 8px;
  background-color: rgba(232,232,255,.62);
  box-shadow: 0 12px 14px -12px rgba(95,92,215,.56);
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 80px;
  padding-right: 80px;
}

.how-redaction-work {
  text-align: center;
  background-color: #ffffff;
  padding: 80px 10px;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.how-redaction-work h1 {
  font-size: 3rem;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 800;
  width: 70%;
  color: #761bcb;
  margin-left: auto;
  margin-right: auto;
  line-height: 3.5rem;
}

.how-redaction-work .steps {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.how-redaction-work .steps .step h3 {
  color: #761bcb;
  font-weight: 800;
  font-size: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.how-redaction-work .steps .step p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: black;
}

.document-classification-demo a {
  width: 184px;
  height: 60px;
  background-color: #5e5cd7;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #dcdcdc;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  position: relative;
  top: -30px;
  padding-top: 0.8rem;
  margin-right: 20px;
  color: white;
  line-height: 31px;
  font-size: 18px;
  font-weight: 500;
}

.features .feature .document-classification h3 {
  color: #5e5cd7;
  padding-top: 33px;
}

.features .details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 23px 10px 0 10px;
}

.features .details .detail {
  width: 30%;
  margin-right: 3%;
  padding-top: 27px;
  padding-bottom: 27px;
}

.features .details .detail h3 {
  color: #5f5cd7;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  padding-top: 30px;
  padding-bottom: 10px;
}

.features .details .detail p {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.78);
}

.pretrained-ai-models .top {
  text-align: center;
  padding-top: 80px;
  background-color: #f9fafb;
}

.pretrained-ai-models .title {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
}

.pretrained-ai-models .sub-title {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
  color: #5f5cd7;
  margin-bottom: 40px;
}

.pretrained-ai-models .top p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 0;
  color: #6b757c;
}

.pretrained-ai-models .top .buttons {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 30px;
}

.pretrained-ai-models .top .buttons .button {
  height: 43px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #dcdcdc;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 20px;
  color: rgb(102, 102, 102);
  line-height: 26px;
  font-size: 15px;
  font-weight: 500;
}

.pretrained-ai-models .top .buttons .selected-button {
  background-color: #d3d3ed;
  color: #5f5cd7;
  height: 43px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #dcdcdc;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 20px;
  line-height: 26px;
  font-size: 15px;
  font-weight: 500;
}

.pretrained-ai-models-demo a {
  width: 184px;
  height: 60px;
  background-color: #5e5cd7;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #dcdcdc;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  padding-top: 0.8rem;
  color: white;
  line-height: 31px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 30px;
  margin-right: 20px;
  margin-bottom: 50px;
}

.pretrained-ai-models-samples-wrapper {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}

.pretrained-ai-models .pretrained-ai-models-samples {
  display: flex;
  width: 100%;
  height: 600px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.pretrained-ai-models .pretrained-ai-models-samples .left {
  width: 50%;
}

.pretrained-ai-models .pretrained-ai-models-samples .right {
  width: 50%;
  background-color: white;
  padding: 1.5rem;
  overflow-y: auto;
}

.custom-training .top {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f9fafb;
}

.custom-training .title {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
}

.custom-training .sub-title {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
  color: #5f5cd7;
  margin-bottom: 40px;
}

.custom-training .top p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-bottom: 0;
  color: #6b757c;
}

.custom-training .bottom {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 80px;
  background-color: #1f2937;
}

.custom-training .bottom-wrapper {
  padding-left: 50px;
  padding-right: 50px;
}

.custom-training .bottom .steps {
  display: flex;
  text-align: left;
}

.custom-training .bottom .steps .left {
  flex-basis: 40%;
}

.custom-training .bottom .steps h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  font-family: 'Roboto';
  color: rgba(255, 255, 255, 0.86)
}

.custom-training .bottom .steps p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Roboto';
  color: rgba(255,255,255,.69);
  margin-bottom: 1rem;
}

.custom-training .bottom .steps .selected-h3 {
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
  font-family: 'Roboto';
  color: white;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.custom-training .bottom .steps .selected-p {
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  font-family: 'Roboto';
  color: rgba(255, 255, 255, .69)
}

.stepButton {
  background-color: #ffffff;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  padding: 0.5rem;
  color: black;
}

.stepButton:hover {
  opacity: 0.8;
}

.custom-training .bottom .steps .right {
  flex-basis: 60%;
}

.custom-training .bottom .circles {
  display: flex;
  text-align: center;
  margin: 60px auto;
  padding-left: 47%;
}

.custom-training .bottom .circle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid white;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}

.custom-training .bottom .filled-circle {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: white;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}

.custom-training .bottom .img {
  margin-bottom: 50px;
}

.custom-training .bottom .details {
  display: flex;
}

.custom-training .bottom .details .left {
  text-align: left;
  flex-basis: 40%;
  padding-right: 5%;
}

.custom-training .bottom .details .left h3 {
  color: #ffffff;
  font-size: 23px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.custom-training .bottom .details .left p {
  color: #ffffffb0;
  font-size: 17px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: -0.5px;
  margin-top: 1rem;
}

.custom-training .bottom .details .left .custom-training-demo a {
  width: 184px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  padding-top: 0.8rem;
  color: black;
  line-height: 31px;
  font-size: 18px;
  font-weight: 500;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 200px;
}

.custom-training .bottom .details .right {
  flex-basis: 60%;
  padding-left: 25px;
}

.custom-training .bottom .details .right iframe {
  width: 100%;
  height: 100%;
}

.consulting-services {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.consulting-services h3 {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
  letter-spacing: -0.5px;
}

.consulting-services p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #6b727c;
  font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
}

.consulting-services .services {
  margin-top: 100px;
  display: flex;
}

.consulting-services .service {
  flex-basis: 30%;
  border-radius: 6px;
  background-color: #f9fafb;
  padding-bottom: 60px;
}

.consulting-services .middle-service {
  margin-left: 3%;
  margin-right: 3%;
}

.consulting-services .icon {
  width: 90px;
  height: 80px;
  position: relative;
  top: -30px;
}

.consulting-services h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #333;
  padding-bottom: 10px;
}

.consulting-services .service p {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.3px;
  color: #6b727c;
  font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
  padding-left: 20px;
  padding-right: 20px;
}

.redactionUseCase {
  background-color: #2e2e2e;
  padding: 100px 10%;
}

.redactionUseCase .redactionUseCaseLayoutHeader p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 30px;
}

.redactionUseCase h1 {
  font-size: 46px;
  font-weight: 900;
  line-height: 46px;
  color: #ffffff;
  margin-bottom: 20px;
}

.redactionUseCasesWrapper h3 {
  font-size: 28px;
  font-weight: 900;
  line-height: 48px;
  color: #ffffff;
}

.redactionUseCase .case p {
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.3px;
  color: rgba(255, 255, 255, 0.9);
}

.redactionUseCase .redactionUseCasesWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 4rem;
}

.pricing-section {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.pricing-section .row > * {
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.contactButton {
  background-color: #d644a0;
  color: #ffffff;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
}

.contactButton:hover {
  background-color: rgba(214, 68, 160, 0.8);
  color: #ffffff;
}

.contactButtonWhite {
  background-color: #ffffff;
  color: black;
  border-radius: 5px;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 500;
}

.contactButtonWhite:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}


.pricingTableContainer {
  max-width: 80%;
  margin: 0 auto;
}

.pricing-section .priceItemWrapper {
  width: 33.3%;
}

.success-in-numbers {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.success-in-numbers h3 {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
  color: black;
  letter-spacing: -0.5px;
}

.success-in-numbers .success-in-numbers-layout {
  display: flex;
  padding-top: 80px;
}

.success-in-numbers .number {
  flex-basis: 33%;
}

.success-in-numbers span {
  font-size: 45px;
  font-weight: 600;
  line-height: 72px;
  font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
}

.success-in-numbers .documents-uploaded {
  color: #d644a0;
}

.success-in-numbers .pages-processed {
  color: #726fd6;
}

.success-in-numbers .ai-models-trained {
  color: #3bb7bc;
}

.success-in-numbers p {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  color: #333;
  margin-top: 20px;
}

.media {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.media h3 {
  font-size: 38px;
  font-weight: 900;
  line-height: 57px;
  color: black;
  letter-spacing: -0.5px;
}

.media .media-layout {
  display: flex;
  padding-top: 80px;
}

.media .media-detail {
  flex-basis: 24.2%;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  height: 100%;
}

.media .detail-wrap {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  border-left: 1px solid rgb(102, 102, 102, 0.2);
  border-right: 1px solid rgb(102, 102, 102, 0.2);
  border-bottom: 1px solid rgb(102, 102, 102, 0.2);
}

.media h5 {
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
}

.media p {
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.5px;
  color: rgb(102, 102, 102);
  margin-top: 50px;
  margin-bottom: 70px;
}

.media .first-ai {
  margin-top: 50px;
}

.media .most-innovative {
  margin-bottom: 45px;
}

.media .media-read-more a {
  width: 120px;
  height: 47px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 2px solid #5f5cd7;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  padding-top: 0.8rem;
  color: #5f5cd7;
  line-height: 21px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
  margin-bottom: 15px;
}

.about-bgl {
  background-color: #f9fafb;
  padding-top: 80px;
  padding-bottom: 80px;
}

.about-bgl .layout {
  display: flex;
}

.about-bgl .left {
  flex-basis: 60%;
  padding-right: 10px;
}

.about-bgl .right {
  flex-basis: 40%;
}

.about-bgl h3 {
  font-size: 32px;
  font-weight: 900;
  line-height: 1.5rem;
  color: #1f2937;
  padding-bottom: 18px;
}

.about-bgl p {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #1f2937;
  padding-bottom: 18px;
}

.footer {
  background-color: #f2f2f2;
}

.footer .layout {
  display: flex;
  padding-top: 30px;
}

.footer .left {
  flex-basis: 30%;
}

.footer .footerSubHeader {
  color: #777777;
}

.footer p {
  font-size: 15px;
  font-weight: 500;
  line-height: 13px;
  color: #414042;
}

.footer span {
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  color: #414042;
}

.footer a {
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  color: #414042;
}

.footer .separator {
  line-height: 0;
}

.footer .middle {
  flex-basis: 30%;
}

.footer .icon {
  margin-bottom: -5px;
  margin-left: 5px;
}

.footer .right {
  flex-basis: 40%;
}

.footer .bottom {
  width: 100%;
  border-top: 1px solid #aaa;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-top: 20px;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  color: #aaa;
}

.footer .bottom img {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  margin-left: 0.5rem;
}

.hero-s3 {
  position: relative;
  padding: 150px 0px 50px 0px;
}

.hero-s3 .shape img {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: auto;
}

.hero-s3__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding-right: 100px;
}

.hero-s3__content .content-sh {
  font-weight: 900;
  font-size: 67px;
  line-height: 67px;
  color: #810ed3;
  margin-top: 40px;
  margin-bottom: 20px;
}

.hero-s3__content .content-h {
  font-weight: 800;
  font-size: 55px;
  line-height: 55px;
  color: #1c1c1c;
  margin-top: 20px;
  margin-bottom: 20px;
}

.hero-s3__content .content-d {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
}

.hero-s3__content .prHeroBtnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.hero-s3__thumb {
  z-index: 9;
}

.hero-s3__thumb img {
  width: 100%;
}

.redactionDataBrief {
  padding-top: 40px;
  padding-bottom: 100px;
  background-color: #761dcb;
  color: white;
}

.redactionDataBrief .row {
  justify-content: space-between;
  align-items: center;
}

.redactionDataBrief .rightWrapper {
  flex-basis: 50%;
}

.redactionDataBrief .content-sh {
  font-weight: 700;
  font-size: 50px;
  color: white;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  margin-bottom: 40px;
}

.redactionDataBrief .content-item {
  display: flex;
}

.redactionDataBrief .content-item .line {
  vertical-align: middle;
  margin-left: 1rem;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 25px;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  width: 80%
}

.redactionDataBrief .content-d {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.redactionDataBrief .content-d a {
  text-decoration: underline;
}

.redactionDataBrief .s-title-wrap {
  text-align: center;
}

.data-protection {
  text-align: center;
  background-color: #f4f4f4;
  padding: 80px 10px;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.data-protection .dataProtectionHeader {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 900;
  width: 70%;
  color: #761bcb;
  margin-left: auto;
  margin-right: auto;
  line-height: 3.5rem;
  font-size: 42px
}

.data-protection p {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.5rem;
  font-size: 19px;
  text-align: left;
  margin-top: 1.5rem;
}

.data-protection .row {
  justify-content: space-evenly;
  align-items: flex-start;
}

.data-protection .left-panel {

}

.data-protection .left-panel h3 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.data-protection .left-panel .dataProtectionItemText {
  display: inline-block;
  font-size: 1.125rem;
  line-height: 2.25rem;
}

.data-protection {
  text-align: center;
  background-color: #f4f4f4;
  padding: 80px 10px;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.data-protection h1 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  width: 45%;
  color: #761bcb;
  margin-left: auto;
  margin-right: auto;
  line-height: 3.5rem;
}

.data-protection p {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 2.5rem;
  font-size: 19px;
  text-align: left;
  margin-top: 1.5rem;
}

.data-protection .left-panel {
  text-align: left;
  padding-top: 1rem;
}

.data-protection .left-panel span {
  display: block;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-size: 1.125rem;
  line-height: 2.25rem;
}

.redactionKeFeatures {
  text-align: center;
  background-color: #2e2e2e;
  padding: 80px 10px;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  color: white;
}

.redactionKeFeatures h1 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  color: white;
  font-size: 50px;
}

.redactionKeFeatures .featuresWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #2e2e2e
}

.redactionKeFeatures .featuresWrapper h3 {
  font-size: 23px;
  font-weight: 900;
  line-height: 23px;
  padding-top: 25px;
  padding-bottom: 15px;
}

.redactionKeFeatures .featuresWrapper p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 16px;
  font-weight: 500;
  line-height: 31px;
}

.slider-with-animated-handle {
  transition: transform 0.2s;
  --divider-width: 2px;
  --divider-color: #761bcb;
}

.slider-with-animated-handle:focus {
  outline: none;
  box-shadow: 0px 0px 5px 2px rgba(107, 107, 107, 0.88);
}

.custom-animated-handle {
  color: #0b0d10;
}

.slider-with-animated-handle:hover .custom-animated-handle {
  transform: scale(1.2);
  cursor: pointer;
}

.data-sanitisation {
  background: #f4f4f4;
}

.data-sanitisation .layout {
  display: flex;
  max-width: 1600px;
}

.data-sanitisation .left-panel {
  background-color: #761bcb;
  flex: 1 1 50%;
  padding-left: 12%;
  padding-top: 8rem;
  padding-right: 4%;
  padding-bottom: 8rem;
  color: white;
  height: 1000px;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
}

.data-sanitisation .left-panel button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit; /* Ensure font family matches surrounding text */
  font-size: inherit; /* Ensure font size matches surrounding text */
  cursor: pointer;
  outline: none; /* Remove focus outline */
  color: inherit; /* Ensure font color matches surrounding text */
  text-align: left; /* Align text to the left, like a regular h3 */
  display: block; /* To occupy the full width like block elements */
}

.data-sanitisation .left-panel h2 {
  color: white;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-size: 56px;
  margin-bottom: 2rem;
}

.data-sanitisation .left-panel h3 {
  color: white;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-size: 36px;
  border-bottom: white 5px solid;
  margin-bottom: 2rem;
}

.data-sanitisation .left-panel .light {
  color: #c8a4ea;
  border-color: #c8a4ea;
}

.data-sanitisation .left-panel p {
  color: white;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-size: 1.25rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}

.data-sanitisation .right-panel {
  flex: 1 1 50%;
  background-color: #f4f4f4;
}

.data-sanitisation .right-panel .before span,
.data-sanitisation .right-panel .after span {
  background: #5F5CD7;
  /*border: 1px solid #761bcb;*/
  border-radius: 6px;
  color: white;
  padding: 8px;
  position: absolute;
  top: 30px;
  transform: translateY(-50%);
  line-height: 100%;
  font-size: 12px;
}

.data-sanitisation .right-panel .after span {
  left: 12px;
}

.data-sanitisation .right-panel .before span {
  right: 12px;
}

.use-case {
  text-align: center;
  background-color: #2e2e2e;
  padding: 80px 10px;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  color: white;
}

.use-case .layout {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10%;
  padding-right: 10%;
}

.use-case h1 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  color: white;
  text-align: left;
  margin-bottom: 2rem;
}

.use-case .cases {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 4rem;
}

.use-case .cases .case {
  width: 33%;
  text-align: left;
  padding-right: 4%;
  padding-bottom: 2.5rem;
}

.use-case h3 {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  color: white;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.use-case p {
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  color: white;
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 300;
}

.try-it-yourself {
  padding: 80px 10px;
}

.try-it-yourself .layout {
  display: flex;
  flex-wrap: wrap;
  padding-left: 10%;
  padding-right: 10%;
}

.try-it-yourself h1 {
  color: #761bcb;
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 48px;
  text-align: left;
  margin-bottom: 2rem;
}

.try-it-yourself .tryItSubtitle {
  font-size: 20px;
  margin-bottom: 20px;
}

.try-it-yourself .steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 30px;
}

.try-it-yourself .steps .step {
  text-align: left;
  padding: 30px 40px 50px 40px;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.try-it-yourself .steps .step a {
  color: blue;
  text-decoration: underline;
}

.try-it-yourself h3 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.try-it-yourself p {
  font-size: 18px;
  line-height: 30px;
  color: black;
}

.try-it-yourself .copyIcon {
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #c64f9d;
}

.try-it-yourself .copyIcon:hover {
  color: #761bcb;
}

.try-it-yourself .email-us {
  width: 100px;
  height: 42px;
  background-color: #c64f9d;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgb(214 68 160 / 0.2);
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  position: relative;
  padding-top: 0.8rem;
  margin-right: 20px;
  color: white;
  line-height: 14px;
  font-size: 14px;
  font-weight: 500;
}

.ready-for-experience .layout {
  /*display: flex;*/
  background-color: #761bcb;
}

.ready-for-experience .left-panel {
  padding: 50px;
  color: white;
}

.ready-for-experience .left-panel h1 {
  color: white;
  font-size: 67px;
  line-height: 80px;
  margin-bottom: 3rem;
}

.ready-for-experience .left-panel h3 {
  color: white;
  font-size: 46px;
  margin-bottom: 3rem;
}

.ready-for-experience .left-panel h3 a {
  font-size: 46px;
  text-decoration: underline;
}

.ready-for-experience .demo {
  width: 180px;
  height: 60px;
  background-color: #f3f3f3;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  vertical-align: baseline;
  position: relative;
  padding-top: 1.5rem;
  margin-right: 20px;
  color: #6c6c6c;
  line-height: 14px;
  font-size: 1.25rem;
  font-weight: 500;
}

.ready-for-experience .right-panel {
  flex: 1 1 33%;
}

/* *********************************** */
/* End of development CSS section */
/* *********************************** */

@media only screen and (max-width: 1600px) {
  .hero-s5 {
    padding: 180px 0;
  }
  .hero-s6 {
    padding: 150px 0 150px;
  }
  .hero-s3 {
    position: relative;
    padding: 150px 0px 35px 0px;
  }

  .hero-s3 .shape img {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100%;
    width: auto;
  }

  .hero-s3__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    padding-right: 120px;
  }

  .hero-s3__content .content-sh {
    font-weight: 800;
    font-size: 59px;
    line-height: 59px;
    color: #761bcb;
    font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  }

  .hero-s3__content .content-h {
    font-weight: 800;
    font-size: 41px;
    line-height: 55px;
    color: #1c1c1c;
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  }

  .hero-s3__content .content-d {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: black;
    margin-bottom: 30px;
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
  }

  .hero-s3__content .buttons {
    display: flex;
  }

  .hero-s3__content .demo {
    width: 130px;
    height: 42px;
    background-color: #c64f9d;
    border-radius: 4px;
    box-shadow: 0px 2px 2px rgb(214 68 160 / 0.2);
    display: inline-block;
    text-align: center;
    vertical-align: baseline;
    position: relative;
    padding-top: 0.8rem;
    margin-right: 20px;
    color: white;
    line-height: 14px;
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  .hero-s3__content .try-it-out {
    width: 130px;
    height: 42px;
    background-color: #f3f3f3;
    border-radius: 4px;
    box-shadow: 0px 2px 4px #dcdcdc;
    display: inline-block;
    text-align: center;
    vertical-align: baseline;
    position: relative;
    padding-top: 0.8rem;
    margin-right: 20px;
    color: #6e6e6e;
    line-height: 14px;
    font-family: 'Open Sans', Helvetica, Arial, Lucida, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  .hero-s3__thumb {
    z-index: 9;
  }
  .hero-s3__thumb .img {
    margin-right: -210px;
    z-index: 9;
  }

  .data-sanitisation .left-panel {
    flex: 1 1 40%;
  }

  .data-sanitisation .left-panel p {
    font-size: 1rem;
    line-height: 2rem;
  }

  .data-sanitisation .right-panel {
    flex: 1 1 60%;
  }

  #home-5 .top-content-sec::after {
    content: "";
    width: 460px;
  }
  .about-s4__thumb,
  .about-s5__thumb,
  .about-s6__thumb {
    margin-right: 0px;
  }
  .about-s5__thumb {
    margin-left: 0px;
  }
  .feature-s4 .content {
    width: 260px;
    height: 260px;
  }
  .header-s2 .m-nav > ul {
    gap: 20px;
  }
  .hero-s2__thumb .img .shp-2 {
    right: 35px;
    top: 95px;
  }
  .hero-s2__content .content-sh {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 25px;
  }
  .hero-s2__content .content-h {
    font-size: 60px;
    line-height: 80px;
  }
  .tab-s1 .tab-links .tab-btn {
    padding: 12px 22px;
    margin-right: 10px;
  }
  .hero-s1__thumb {
    padding: 0px;
  }
  .hero-s1__thumb .img {
    margin-right: 0;
  }
  .about-s1__thumb {
    gap: 20px;
    margin: 0;
    height: 100%;
    align-items: center;
  }

  .about-s1__thumb > * {
    flex-shrink: 1;
  }
}

@media only screen and (max-width: 1200px) {

  .home5-header {
    display: none;
  }

  .header-s6 {
    display: none;
  }

  .home5-header-mobile-header {
    display: block;
  }

  #home-5 .top-content-sec {
    display: none;
  }

  .hero-s5 {
    margin-top: 0px;
  }

  .header-s6-mobile-header {
    display: block;
  }

  .header-s3__bottom .p-right,
  .header-s4__bottom .p-right,
  .header-s6__bottom .p-right {
    display: none;
  }
  .hero-s3__content .content-h {
    font-size: 40px;
    line-height: 40px;
  }
  .hero-s3__content .content-sh {
    font-size: 45px;
    line-height: 45px;
  }
  .hero-s3__content .content-d {
    margin-bottom: 45px;
  }

  .redactionKeFeatures .featuresWrapper {
    grid-template-columns: 1fr 1fr;
  }

  .try-it-yourself .steps {
    grid-template-columns: 1fr;
  }

  .redactionKeFeatures .features {
    grid-template-columns: 1fr 1fr;
  }

  .redactionUseCase .redactionUseCasesWrapper {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  .hero-s6 {
    padding-bottom: 250px;
  }
  .about-s4__content,
  .about-s6__content {
    padding: 0;
  }
  .about-s5__content {
    padding-left: 20px;
  }
  .feature-s4 .content {
    width: 230px;
    height: 230px;
    margin-left: 10px;
  }
  .feature-s4 .content h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .feature-s4.third .content {
    margin-left: 15px;
  }
  .contact-banner-s1 {
    padding-bottom: 100px;
  }
  .footer-s1 .widget-3,
  .footer-s2 .widget-3,
  .footer-s1 .widget-4,
  .footer-s2 .widget-4 {
    padding-top: 80px;
  }
  .b-post-s1 > .content .btm .readmore,
  .b-post-s2 > .content .btm .readmore,
  .b-post-s4 > .content .btm .readmore,
  .b-post-s6 > .content .btm .readmore {
    font-size: 14px;
  }
  .b-post-s1 > .content .meta li .text,
  .b-post-s2 > .content .meta li .text,
  .b-post-s4 > .content .meta li .text,
  .b-post-s6 > .content .meta li .text {
    font-size: 12px;
  }
  .b-post-s1 > .content .title,
  .b-post-s2 > .content .title,
  .b-post-s4 > .content .title,
  .b-post-s6 > .content .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-top: 7px;
    transition: all 0.1s ease-in-out 0s;
  }
  .testimonial-area-s1__thumb {
    padding-top: 80px;
    text-align: center;
  }
  .home5-testimonial-area-s5 .testimonial-area-s1__thumb {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .testimonial-area-s1__content,
  .testimonial-area-s3__content {
    padding-top: 0 !important;
  }
  .header-s3__top .p-left {
    gap: 35px;
  }
  .m-nav > ul {
    gap: 33px;
  }
  .m-nav li.dropdown .d-menu {
    min-width: 190px;
  }
  .m-nav li.dropdown .d-menu li a {
    font-size: 15px;
  }
  .header-s1,
  .header-s2 {
    background: #fff;
  }
  .header-s1__bottom {
    box-shadow: none;
    padding: 15px 0px;
  }
  .header-s1__top {
    display: none;
  }
  .header-s1__bottom > .p-left {
    display: none;
  }
  .header-s1__bottom > .logo {
    display: flex;
  }
  .header-s2 .header-s1__bottom .p-right {
    display: none;
  }
  .header-s2 .header-s1__bottom::after {
    display: none;
  }
  .hero-s2 {
    padding-top: 220px;
    padding-bottom: 120px;
  }
  .sponsers-slider-s1 .sponsers-container,
  .sponsers-slider-s2 .sponsers-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .sponsers-container .s-sponser:nth-of-type(5),
  .sponsers-container .s-sponser:nth-of-type(6) {
    display: none;
  }
  .hero-s4__content .content-sh,
  .hero-s5__content .content-sh,
  .hero-s1__content .content-sh {
    font-size: 20px;
    line-height: 25px;
  }
  .hero-s4__content .content-h,
  .hero-s5__content .content-h,
  .hero-s1__content .content-h {
    font-size: 46px;
    line-height: 55px;
  }
  .hero-s4__content .content-d,
  .hero-s5__content .content-d,
  .hero-s1__content .content-d {
    font-size: 16px;
    line-height: 28px;
  }
  .hero-s1 .shapes .shp-4 {
    display: none;
  }
  .feature-s1,
  .feature-s2,
  .feature-s3,
  .feature-s6,
  .feature-s7,
  .feature-s9,
  .feature-s10 {
    padding: 15px;
  }
  .feature-s1 > .content h4,
  .feature-s2 > .content h4,
  .feature-s3 > .content h4,
  .feature-s6 > .content h4,
  .feature-s7 > .content h4,
  .feature-s9 > .content h4,
  .feature-s10 > .content h4 {
    font-size: 22px;
    line-height: 28px;
  }
  .feature-s1 > .content p,
  .feature-s2 > .content p,
  .feature-s3 > .content p,
  .feature-s6 > .content p,
  .feature-s7 > .content p,
  .feature-s9 > .content p,
  .feature-s10 > .content p {
    font-size: 14px;
  }

  .about-s1__content > .content-b {
    gap: 30px;
    padding-top: 30px;
  }

  .pricing-t-s1 > .pt-header .pt-ribbon,
  .pricing-t-s2 > .pt-header .pt-ribbon {
    font-size: 20px;
  }

  /*.pricing-t-s1 > .pt-body .pt-features li .text,*/
  /*.pricing-t-s2 > .pt-body .pt-features li .text {*/
  /*  font-size: 16px;*/
  /*}*/
  .funfact-s1,
  .funfact-s3 {
    flex-direction: column;
  }

  .funfact-s1 .content,
  .funfact-s3 .content {
    text-align: center;
  }

  .about-s3 {
    margin-top: -160px;
  }
  .tab-s1 .tab-contents .tab-pane .list-b .i-list li .text {
    font-size: 13px;
  }
  .tab-s1 .tab-contents .tab-pane .list-b {
    gap: 15px;
  }
  .tab-s1 .tab-links .tab-btn {
    margin-bottom: 12px;
  }
  .about-s2__thumb {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .testimonial-area-s2 .testimonial-area-s1__thumb {
    padding-top: 0px;
  }
  .about-s6__thumb {
    margin-left: 0px;
  }
  .about-s6__content .content-b {
    gap: 20px;
  }
  .testimonial-area-s3__facts {
    padding-top: 60px;
  }
  .testimonial-area-s3__facts .p-right {
    padding-bottom: 0px;
  }
  .testimonial-area-s3__facts .p-left {
    padding-top: 0px;
  }
  .t-member-details__container {
    flex-wrap: wrap;
  }
  .t-member-details__container .member-info {
    flex-basis: 50%;
    flex-shrink: 0;
  }
  .t-member-details__container .member-thumb {
    flex-basis: 50%;
    flex-shrink: 0;
  }
  .t-member-details__container .member-stats {
    flex-basis: 100%;
    flex-shrink: 0;
    margin-top: 25px;
  }
  .service-detail-side .service-c-list .c-list li a .text {
    font-size: 14px;
    margin-left: 8px;
  }
  .portfolio-detail__side .p-detail-box .details li .value {
    font-size: 15px;
  }
  .contact-detail-box .info-list li a {
    font-size: 14px;
  }
  .pricing-t-s2 > .pt-header .price .p-suf {
    font-size: 15px;
  }
  .single-blog-content {
    padding-right: 0px;
  }
  .s-post-tag-share-wrap {
    align-items: self-start;
    flex-direction: column;
    gap: 30px;
  }
  .more-post-section .post .thumb img {
    width: 100%;
  }

  .more-post-section {
    flex-direction: column;
    gap: 30px;
  }

  .contact-form-area .contact-info {
    padding-right: 20px;
  }

  .data-sanitisation .left-panel {
    flex: 1 1 33%;
    padding-left: 5%;
  }

  .data-sanitisation .left-panel h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .data-sanitisation .left-panel h3 {
    font-size: 28px;
    line-height: 40px;
  }

  .data-sanitisation .right-panel {
    flex: 1 1 66%;
  }
}

@media only screen and (max-width: 992px) {
  .d-lg-none {
    display: none;
  }

  .flex-lg-col-reverse {
    flex-direction: column-reverse;
  }

  .justify-content-lg-center {
    flex-wrap: wrap;
    justify-content: center;
  }

  .header .icon {
    display: none;
  }

  .header .small-icon {
    display: block;
    float: left;
    margin-right: 14%;
    margin-left: 5%;
    margin-bottom: 15px;
  }

  .header .menus {
    display: none;
  }

  .header .drawer-button {
    display: inline-block;
    position: absolute;
    right: 5%;
    top: 15px;
  }

  .header-s3 {
    display: none;
  }

  .mobile-header {
    display: block;
  }

  .hero-s3 .shape img {
    display: none;
  }

  .hero-s3__content {
    align-items: center;
  }
  .hero-s3__content .content-h {
    text-align: center;
  }
  .hero-s3__content .content-d {
    text-align: center;
  }
  .header-s4 {
    display: none;
  }
  .testimonial-area-s3__content {
    padding-right: 0px;
  }
  .about-s7 .about-s7__content,
  .about-s8 .about-s7__content,
  .about-s7 .about-s8__content,
  .about-s8 .about-s8__content {
    padding-right: 0px;
  }
  .feature-area-inner > * {
    flex-basis: 50%;
  }
  .feature-s4 .content {
    width: 250px;
    height: 250px;
  }
  .feature-s4.third {
    display: none;
  }
  .about-s4__content .content-b,
  .about-s6__content .content-b {
    gap: 30px;
  }
  .about-s9__thumb {
    margin-top: 70px;
  }
  .about-s4__thumb,
  .about-s5__thumb,
  .about-s6__thumb {
    margin-top: 120px;
  }
  .about-s5__thumb {
    display: none;
  }

  .ready-for-experience .left-panel h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .ready-for-experience .left-panel h3 {
    font-size: 30px;
    line-height: 40px;
  }

  .ready-for-experience .left-panel h3 a {
    font-size: 30px;
    text-decoration: underline;
  }

  .contact-banner-s1__content {
    margin-top: 18px;
  }
  .footer-s1 .footer-cta,
  .footer-s2 .footer-cta {
    padding-bottom: 45px;
  }
  .funfact-s2 {
    flex-direction: column;
    justify-content: center;
  }
  .funfact-s2 .content {
    text-align: center;
  }
  .footer-s1 .footer-cta,
  .footer-s2 .footer-cta {
    flex-direction: column;
    gap: 30px;
    align-items: stretch;
  }
  footer .f-menu {
    align-items: center;
  }
  .footer-s1 .widget-1,
  .footer-s2 .widget-1 {
    text-align: center;
  }
  .footer-s1 .widget-2,
  .footer-s2 .widget-2 {
    text-align: center;
    padding-top: 50px;
  }
  .footer-s1 .widget-3,
  .footer-s2 .widget-3 {
    text-align: center;
    padding-top: 50px;
  }
  .recent-posts > .rp-single {
    align-items: center;
    justify-content: center;
  }
  .recent-posts > .rp-single .content {
    text-align: left;
  }
  .footer-s1 .widget-4,
  .footer-s2 .widget-4 {
    text-align: center;
    padding-top: 30px;
  }
  .footer-cr .footer-cr-container .p-left p {
    font-size: 12px;
  }
  .footer-cr .footer-cr-container ul.cr-menu a {
    font-size: 12px;
  }
  .hero-s1 {
    margin-top: 0px;
    padding-top: 110px;
    padding-bottom: 122px;
  }
  .hero-s1__thumb {
    display: none;
  }
  .hero-s1__content {
    text-align: center;
    align-items: center;
  }
  .hero-s1__content .content-d {
    padding-right: 0px;
  }
  .feature-s1,
  .feature-s2,
  .feature-s3,
  .feature-s6,
  .feature-s7,
  .feature-s9,
  .feature-s10 {
    margin-bottom: 25px;
  }
  .about-s1__content {
    padding-top: 60px;
  }

  .contact-banner-s1 {
    padding-bottom: 100px;
  }

  .contact-banner-s1__thumb {
    display: none;
  }

  .pricing-section-s1 .row {
    align-items: center;
    justify-content: center;
  }

  .pricing-section .priceItemWrapper {
    width: 50%;
  }

  .pricing-section-s1 .row .col-md-6:last-child {
    padding-top: 25px;
  }

  .v-play-btn {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  .funfact-area-s1 .video-thumb {
    margin-top: -185px;
  }
  .funfact-area-s1 {
    margin-top: 140px;
    padding-bottom: 75px;
  }
  .funfact-s1,
  .funfact-s3 {
    margin-bottom: 25px;
  }
  .header-s1,
  .header-s2 {
    display: none;
  }
  .hero-s2 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .hero-s2__content {
    padding-top: 80px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .features-slider-s2 {
    padding-bottom: 85px;
  }

  .data-sanitisation .layout {
    flex-direction: column;
  }

  .data-sanitisation .left-panel {
    flex: 1 1 100%;
  }

  .project-slider-area-s1 .slick-track {
    margin-left: 0px;
  }
  .contact-banner-s2 .contact-banner-inner {
    padding: 60px 50px;
  }
  .contact-banner-s2__button {
    flex-shrink: 0;
  }
  .about-s3 .about-s2__thumb {
    display: none;
  }
  .testimonial-area-s2 .testimonial-area-s1__thumb {
    padding-bottom: 60px;
  }
  .about-s2 .about-s2__thumb {
    padding-bottom: 60px;
  }
  .footer-s2 .footer-cta > .p-right .f-subs-form input {
    background: linear-gradient(89.02deg, #6675ff 0%, #69a4ff 100%) !important;
  }
  .footer-s2 .footer-cta > .p-right .f-subs-form {
    background: transparent !important;
  }
  .header-s2 {
    display: none;
  }
  .d-page-banner {
    margin-top: 0px;
    padding-top: 0;
    height: 230px;
  }
  .d-page-banner .content h1 {
    font-size: 40px;
    line-height: 40px;
  }
  .about-s6__content {
    padding-top: 60px;
  }
  .about-s7__thumb {
    padding-top: 60px;
  }
  body#about .features-slider {
    padding-bottom: 0;
  }
  .about-s8__thumb {
    text-align: center;
  }
  .about-s8__thumb .shape {
    display: none;
  }
  .about-s8__content {
    padding-top: 60px;
  }
  body#team .team-area-s2 .col-md-6:nth-of-type(1),
  body#team .team-area-s2 .col-md-6:nth-of-type(2) {
    margin-bottom: 25px;
  }
  .t-member-details__container .member-info {
    flex-basis: 100%;
    padding-bottom: 50px;
  }
  .t-member-details__container .member-thumb {
    flex-basis: 100%;
    margin-top: 25px;
  }
  .member-achievement__container .box {
    flex-shrink: 1;
    flex-basis: 46%;
  }
  .member-achievement__container .box:nth-child(5) {
    display: none;
  }
  .member-achievement__container {
    flex-wrap: wrap;
  }
  .service-detail-side {
    padding-top: 0px;
  }
  .portfolio-detail__side .widget {
    margin-top: 30px;
  }
  .row .col-md-6:nth-child(3) {
    margin-top: 25px;
  }
  .accordion-s1 .s-accordion > .s-accordion__h h3 {
    font-size: 14px;
  }
  .b-post-gallery-s1 .row .col-md-6:nth-child(3) {
    margin-top: 0;
  }
  .b-post-gallery-s2 .col-md-6:nth-child(3) {
    margin-top: 0;
  }
  .latest-posts-widget .recent-posts > .rp-single {
    justify-content: flex-start;
  }

  .contact-form-area .contact-info {
    align-items: stretch;
  }

  .contact-form-area .contact-info {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .hero-s5 {
    padding: 150px 0;
  }

  .hero-s4 {
    padding: 130px 0 140px;
  }

  .hero-s3 {
    padding: 100px 0;
  }

  .hero-s3__content {
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
  }

  .hero .layout {
    display: flex;
    flex-direction: column-reverse;
  }

  .hero .layout .left {
    width: 90%;
  }

  .hero .layout .right {
    width: 90%
  }

  .hero .layout .right .sub-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .about-s1 .col-lg-6 .bg img {
    display: none;
  }

  .btn-group {
    flex-direction: column;
  }

  .features-slider {
    padding-bottom: 65px;
  }

  .features .sub-title {
    margin-bottom: 25px;
  }
  .features .title {
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
  }
  .features .row {
    display: flex;
    flex-direction: column;
  }
  .features .container-wrapper {
    padding: 0;
  }
  .features .feature {
    flex-basis: 50%;
    padding: 0 50px;
  }
  .pretrained-ai-models .container-wrapper {
    padding: 0 20px;
  }
  .pretrained-ai-models .top {
    padding-top: 50px;
  }
  .pretrained-ai-models .title {
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
  }
  .pretrained-ai-models .sub-title {
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
    color: #5f5cd7;
    margin-bottom: 40px;
  }

  .pretrained-ai-models .top p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }

  .pretrained-ai-models .top .buttons .button {
    font-size: 12px;
    padding: 0.5rem;
  }

  .pretrained-ai-models .top .buttons .selected-button {
    font-size: 12px;
    padding: 0.5rem;
  }

  .modelTab {
    font-size: 0.7rem !important;
  }

  .pretrained-ai-models .pretrained-ai-models-samples {
    flex-direction: column;
    height: 90vh;
    padding-top: 10px;
  }

  .pretrained-ai-models .pretrained-ai-models-samples .left {
    flex-basis: 100%;
    height: 50%;
    margin: 0;
    width: 100%;
  }

  .pretrained-ai-models .pretrained-ai-models-samples .right {
    flex-basis: 100%;
    height: 50%;
    margin: 0;
    width: 100%;
  }

  .pricing-section .priceItemWrapper {
    width: 60%;
    margin-bottom: 30px;
  }

  .custom-training .container-wrapper {
    padding: 0 20px;
  }

  .custom-training .top {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .custom-training .title {
    font-size: 24px;
    font-weight: 900;
    line-height: 34px;
  }
  .custom-training .sub-title {
    font-size: 24px;
    line-height: 34px;
  }
  .custom-training .top p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
  .custom-training .bottom .steps .left {
    display: none;
  }
  .custom-training .bottom .steps .right {
    flex-basis: 100%;
  }
  .custom-training .bottom .circles {
    justify-content: center;
    margin: 10px 0;
    padding: 0;
  }
  .custom-training .bottom .img {
    margin-top: 50px;
  }
  .custom-training .bottom .details {
    flex-direction: column-reverse;
  }
  .custom-training .bottom .details .left {
    text-align: center;
    flex-basis: 100%;
    padding: 0;
  }
  .custom-training .bottom .details .left .custom-training-demo {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .custom-training .bottom .details .right {
    flex-basis: 100%;
    width: 100%;
    padding: 0;
  }

  .custom-training .bottom .details .right iframe {
    width: 100%;
    height: 280px;
  }

  .consulting-services {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .consulting-services .services {
    flex-direction: column;
  }

  .consulting-services .services .service {
    flex-basis: 100%;
    margin-bottom: 50px;
  }

  .consulting-services .container-wrapper {
    padding: 0 20px;
  }

  .consulting-services h3 {
    font-size: 24px;
    line-height: 34px;
  }

  .consulting-services p {
    font-size: 16px;
    line-height: 26px;
  }
  .use-case .container-wrapper {
    padding: 0 20px;
  }
  .use-case .use-case-layout {
    flex-direction: column;
  }
  .use-case .use-case-layout .left {
    flex-basis: 100%;
    padding: 0;
  }
  .use-case h3 {
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
  }
  .use-case p {
    font-size: 16px;
    line-height: 26px;
  }
  .use-case .use-case-layout .right {
    flex-basis: 100%;
    padding: 0;
  }
  .use-case .use-case-layout .right iframe {
    width: 100%;
    height: 280px;
  }
  .success-in-numbers {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .success-in-numbers h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .success-in-numbers .success-in-numbers-layout {
    flex-direction: column;
    padding-top: 20px;
  }
  .success-in-numbers .container-wrapper {
    padding: 0 20px;
  }
  .media {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .media .container-wrapper {
    padding: 0 20px;
  }
  .media h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .media .media-layout {
    display: grid;
    padding-top: 50px;
    grid-template: 1fr 1fr / 1fr 1fr;
  }
  .media .media-detail {
    margin-bottom: 20px;
  }
  .about-bgl {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .about-bgl .container-wrapper {
    padding: 0 20px;
  }
  .about-bgl h3 {
    font-size: 24px;
    line-height: 34px;
  }
  .about-bgl p {
    font-size: 16px;
    line-height: 26px;
  }
  .about-bgl .layout {
    flex-direction: column;
  }
  .about-bgl .left {
    flex-basis: 100%;
    padding-right: 0;
  }
  .about-bgl .right {
    flex-basis: 100%;
  }
  .about-s1__content > .content-b .btn {
    display: none;
  }
  .feature-area-s1 {
    padding-bottom: 65px;
  }
  .pricing-section-s1 .row .col-md-6 {
    padding-top: 25px;
  }
  .pricing-section-s1 .row .col-md-6:first-child {
    padding-top: 0;
  }
  .funfact-area-s1 {
    margin-top: 0px;
  }
  .funfact-area-s1 .video-thumb {
    margin-top: 90px;
  }
  .blog-area-s1 .row .col-md-6:nth-child(2) {
    padding-top: 25px;
  }
  .v-play-btn {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
  .v-play-btn svg {
    width: 25px;
  }
  #home-3 .about-s4,
  #home-3 .about-s6 {
    padding-top: 90px;
  }
  .hero-s3__content .content-sh {
    font-size: 28px;
    line-height: 34px;
  }
  .hero-s3__content .content-h {
    font-size: 24px;
    line-height: 34px;
  }
  .hero-s3__content .content-d {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .s-sub-title {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .s-title {
    font-size: 30px;
    line-height: 40px;
  }
  .about-s4__content .content-b,
  .about-s6__content .content-b {
    flex-direction: column;
    gap: 20px;
  }
  .about-s5__content {
    padding-left: 0px;
  }

  .funfact-area-s2-container {
    flex-direction: column;
    gap: 30px;
  }

  .pricing-t-s6.active {
    margin-top: 25px;
  }

  .pricing-t-s2.active {
    margin-top: 25px;
  }

  .redactionKeFeatures h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .redactionKeFeatures .featuresWrapper h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .redactionKeFeatures .featuresWrapper p {
    font-size: 14px;
    line-height: 22px;
  }

  .data-protection {
    padding: 50px 10px;
  }

  .data-protection .dataProtectionHeader {
    font-size: 24px;
    line-height: 30px;
  }

  .data-protection p {
    font-size: 14px;
    line-height: 22px;
  }

  .data-protection .left-panel {
    flex-basis: 100%;
    padding: 0 50px;
    align-items: center;
  }

  .data-protection .right-panel {
    display: none;
  }

  .data-protection .left-panel h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .data-protection .left-panel .dataProtectionItemText {
    font-size: 14px;
    line-height: 22px;
  }

  .redactionDataBrief .redactionDataBrImgWrapper {
    display: none;
  }


  .redactionDataBrief .rightWrapper {
    flex-basis: 100%;
    padding: 0 20px;
  }

  .redactionDataBrief .content-sh {
    font-size: 28px;
    line-height: 34px;
  }

  .redactionDataBrief .content-h {
    font-size: 24px;
    line-height: 34px;
  }

  .redactionDataBrief .content-d {
    font-size: 18px;
    line-height: 22px;
  }

  .redactionDataBrief .content-item .line {
    font-size: 14px;
    line-height: 22px;
  }

  .how-redaction-work h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .how-redaction-work .steps .step h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .how-redaction-work .steps .step p {
    font-size: 14px;
    line-height: 22px;
  }

  .redactionUseCase h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .redactionUseCasesWrapper h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .redactionUseCase .case p {
    font-size: 14px;
    line-height: 22px;
  }

  .redactionUseCase .redactionUseCaseLayoutHeader p {
    font-size: 14px;
    line-height: 22px;
  }

  .try-it-yourself h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .try-it-yourself .tryItSubtitle {
    font-size: 18px;
    line-height: 22px;
  }

  .try-it-yourself h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .try-it-yourself p {
    font-size: 14px;
    line-height: 22px;
  }

  .footer .layout {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
  }

  .footer .right {
    margin-top: 2rem;
  }

  .footer .container-wrapper {
    padding: 0 20px;
  }

  .footer-s1 .footer-cta > .p-right .f-subs-form,
  .footer-s2 .footer-cta > .p-right .f-subs-form {
    width: 100%;
    flex-direction: column;
    background: #fff;
    padding: 0;
    height: auto;
  }

  .footer-s1 .footer-cta > .p-right .f-subs-form input,
  .footer-s2 .footer-cta > .p-right .f-subs-form input {
    background: #ecf5ff;
    width: 100%;
    display: block;
    height: 60px;
    padding: 0 20px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .footer-s1 .footer-cta > .p-right .f-subs-form button,
  .footer-s2 .footer-cta > .p-right .f-subs-form button {
    width: 100%;
  }
  .s-project-s2 .thumb img {
    width: 100%;
  }
  .footer-cr .footer-cr-container {
    flex-direction: column;
    gap: 10px;
  }
  .hero-s2__content .content-h {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 30px;
  }
  .hero-s2__thumb .img .b-img {
    display: none;
  }
  .hero-s2__thumb .img .shp-2 {
    z-index: -1;
  }
  .sponsers-slider-s1 .sponsers-container,
  .sponsers-slider-s2 .sponsers-container {
    grid-template-columns: 1fr 1fr;
  }
  .sponsers-slider-s1 .sponsers-container .s-sponser,
  .sponsers-slider-s2 .sponsers-container .s-sponser {
    margin-bottom: 25px;
  }
  .blog-area-s2 .row .col-md-6:nth-child(1) {
    padding-bottom: 25px;
  }
  .about-s3 .about-s3__content {
    padding: 0;
  }
  .about-s2__content .content-b .i-box:last-child {
    display: none;
  }
  .features-slider-s2 {
    padding-bottom: 60px;
  }
  .contact-banner-s2 .contact-banner-inner {
    flex-direction: column;
    padding: 25px;
    gap: 30px;
  }
  .about-s3 {
    margin-top: -170px;
  }
  .tab-s1 .tab-contents .tab-pane .list-b .i-list:first-child {
    display: none;
  }
  .single-team-s1 .thumb,
  .single-team-s2 .thumb {
    text-align: center;
  }
  .single-team-s1 .thumb img,
  .single-team-s2 .thumb img {
    display: inline;
  }
  .contact-banner-s3 .content h2 {
    font-size: 32px;
    line-height: 40px;
    width: 100%;
  }
  body#team .team-area-s2 .col-md-6:nth-of-type(3) {
    margin-bottom: 25px;
  }
  .portfolio-gallery-container .portfolio-item {
    width: 100%;
  }
  .pagination li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
  .latest-posts-widget .recent-posts > .rp-single {
    flex-direction: row;
    justify-content: flex-start;
  }
  .recent-posts > .rp-single .content {
    flex-basis: 45%;
  }
  .more-post-section {
    margin-top: 80px;
  }
  .comment-form .input-group {
    flex-direction: column;
  }
  .comment-form .input-group > * {
    flex-basis: auto;
  }
  .single-blog-content .post-title {
    font-size: 32px;
    line-height: 42px;
    margin-top: 15px;
  }
  .comments-section {
    margin-top: 80px;
  }
  .comment-form {
    margin-top: 80px;
    text-align: center;
    margin-bottom: 80px;
  }
  .s-comment-inner {
    flex-direction: column;
    align-items: flex-start;
  }
  .comment-form h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .comments-section .title {
    font-size: 30px;
    line-height: 40px;
  }
  .contact-form-s1 {
    padding: 25px;
  }

  .contact-form-area .contact-info {
    padding-right: 0 !important;
  }
}

@media only screen and (max-width: 576px) {
  .hero-s5 {
    padding: 120px 0;
  }

  .redactionKeFeatures .features {
    grid-template-columns: 1fr;
  }

  .redactionUseCase .redactionUseCasesWrapper {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .pricingTableContainer {
    max-width: 90%;
    margin: 0 auto;
  }

  .ready-for-experience .left-panel h1 {
    font-size: 26px;
    line-height: 30px;
  }

  .ready-for-experience .left-panel h3 {
    font-size: 20px;
    line-height: 24px;
  }

  .ready-for-experience .left-panel h3 a {
    font-size: 20px;
    text-decoration: underline;
  }

  .footer .layout {
    display: grid;
    grid-template-columns: 1fr;
  }

  .pricing-section .priceItemWrapper {
    width: 100%;
    margin-bottom: 30px;
  }

}

@media only screen and (max-width: 500px) {
  .about-s11__content .founder-wrap {
    padding: 15px;
  }

  .pricingTableContainer {
    max-width: 98%;
    margin: 0 auto;
  }

  .footer-s1 .footer-cta,
  .footer-s2 .footer-cta {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 425px) {
  .about-s11__content .founder-wrap {
    flex-direction: column;
    justify-content: flex-start;
  }
  .media .media-layout {
    display: grid;
    padding-top: 50px;
    grid-template-columns: 1fr;
  }

  .about-s11__content .founder-wrap .founder-identity {
    align-items: flex-start;
  }

  .about-s11__content .founder-wrap .founder-identity .founder-name {
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 0px;
  }

  .about-s11__content
  .founder-wrap
  .founder-identity
  .founder-name
  .founder-signature-img {
    display: inline-block;
  }

  .about-s11__content .founder-wrap .founder-signature {
    display: none;
  }

  .redactionKeFeatures .featuresWrapper {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .v-play-btn {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
  .v-play-btn svg {
    width: 20px;
  }
}

/*# sourceMappingURL=style.css.map */
